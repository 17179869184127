

export const RepeatBtn = ({onHandleClick}) => {;

    return (
        <tr style={{borderBottom: 'none', borderColor: 'white'}}>
            <td colSpan={3} className='text-center'>
                <button className="btn w-100" onClick={onHandleClick}>Повторити замовлення</button>
            </td>
        </tr>
    )
}