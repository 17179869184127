

import { useField } from "formik";
import { ReactComponent as FlagIcon } from "../../../assets/images/icons/ua_flag.svg";

export const PhoneInput = ({label, ...props}) => {
    const [field, meta] = useField(props);

    return (
        <div className="mb-3">
        <label  className="form-label fs-6 fw-bold">Номер телефону</label>
        <div className="input-group">
            <span className="input-group-text " id="inputGroup-sizing-default">
                <FlagIcon />
                +38
            </span>
            <input className="form-control"  pattern="\d*" maxLength="10" {...field} {...props}/>
        </div>
        {meta.touched && meta.error ? (
            <div className="error-message">{meta.error}</div>
            ) : null
        }
    </div>
    )
}