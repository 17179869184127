import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "./cartSlice";
import { useCallback, useState, useRef } from "react";


export const Add = ({ rest }) => {
    const dispatch = useDispatch();
    const hasPayment = useSelector(state => state.header.hasPayment);
    const count = useSelector(state => state.cart.count);
    const [isClicked, setIsClicked] = useState(false);
    const addButtonRef = useRef(null);  // Ссылка на кнопку "Додати"

    const addedData = (rest) => {
        const { id, modificators } = rest;

        const result = {
            id: id,
            modificators: [],
            qty: 1
        };

        for (let key in modificators) {
            if (modificators.hasOwnProperty(key)) {
                const defaultModificators = modificators[key].modificators.filter(item => item.default);
                result.modificators.push(...defaultModificators);
            }
        }

        return result;
    };

    const handleAddToCart = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        dispatch(addToCart(addedData(rest)));
        setIsClicked(true);

        const circle = document.createElement('div');
        circle.className = 'circle';
        document.body.appendChild(circle);

        const cartButton = document.querySelector('.cart-button');
        // if (!cartButton) {

        //     console.log("Кнопка корзины не найдена. Товар добавлен без анимации.");
        //     document.body.removeChild(circle);
        //     return;
        // }

        const cartButtonRect = cartButton.getBoundingClientRect();
        const addButtonRect = addButtonRef.current.getBoundingClientRect();

        const startX = addButtonRect.left + window.scrollX;
        const startY = addButtonRect.top + window.scrollY;
        const endX = cartButtonRect.left + window.scrollX + cartButtonRect.width / 2;
        const endY = cartButtonRect.top + window.scrollY + cartButtonRect.height / 2;

        circle.style.position = 'absolute';
        circle.style.left = `${startX}px`;
        circle.style.top = `${startY}px`;
        circle.style.width = '20px';
        circle.style.height = '20px';
        circle.style.borderRadius = '50%';
        circle.style.pointerEvents = 'none';

        circle.animate([
            { transform: `translate(${startX - startX}px, ${startY - startY}px)`, opacity: 1 },
            { transform: `translate(${endX - startX}px, ${endY - startY}px)`, opacity: 0, offset: 1 }
        ], {
            duration: 800,
            easing: 'ease-in-out',
            fill: 'forwards'
        }).onfinish = () => {

            document.body.removeChild(circle);
        };

    }, [dispatch, rest]);

    if (!hasPayment) {
        return null;
    }

    return (
        <>
            <button ref={addButtonRef} className="add" onClick={handleAddToCart} disabled={isClicked}>
                Додати
            </button>
        </>
    );
};
