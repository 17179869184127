import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hook/http.hook";


const cartAdapter = createEntityAdapter();

const cartStorage = JSON.parse(localStorage.getItem('cart'));

const initialState = cartAdapter.getInitialState({
    cart: cartStorage || [],
    cartId: localStorage.getItem('cartId') || null,
    cartLoadingStatus: 'idle',
    cartTotal: 0,
    count: cartStorage ? Object.keys(cartStorage).length : 0,
});

export const createOrder = createAsyncThunk(
    'cart/createOrder',
    async () => {
        const { request } = useHttp();

        const cart = JSON.parse(localStorage.getItem('cart'));
        const shipping = JSON.parse(localStorage.getItem('shipping'));
        const payment = localStorage.getItem('payment');

        const response = await request(
            `order/create`,
            `POST`,
            JSON.stringify({
                cart: cart,
                shipping: shipping,
                payment: payment,
            })
        );
        
        return response;
    }
)

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCartId(state, action) {
            state.cartId = action.payload
        },
        setCart(state, action) {
            state.cart = action.payload;
            state.count = state.cart.length;
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        addToCart(state, action) {
            state.cart.push(action.payload);
            state.count = state.cart.length;
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        setModificator(state, action) {
            const { productId, modificator, type } = action.payload;
            const product = state.cart.find(item => item.id === productId);
            
            if (product) {
                if (!product.modificators) {
                    product.modificators = [];
                }
        
                const index = product.modificators.findIndex(mod => mod.id === modificator.id);
                
                if (index === -1) {
                    if(type === 'radio' || type === 'default') {
                        product.modificators[0] = modificator;
                    } else {
                        product.modificators.push(modificator);
                    }
                } else {
                    product.modificators.splice(index, 1);
                }
        
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
        },
        incrementQuantity(state, action) {
            const { id } = action.payload;
            const item = state.cart.find(item => item.id === id);
            if (item) {
                item.qty += 1;
            }
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        decrementQuantity(state, action) {
            const { id } = action.payload;
            const item = state.cart.find(item => item.id === id);
            if (item) {
                if (item.qty > 1) {
                    item.qty -= 1;
                } else {
                    state.cart = state.cart.filter(item => item.id !== id);
                }
            }
            localStorage.setItem('cart', JSON.stringify(state.cart));
            state.count = state.cart.length;
        },
        changeQuantity(state, action) {
            const { id, qty } = action.payload;
            if (qty > 0) {
                const item = state.cart.find(item => item.id === id);
                if (item) {
                    item.qty = qty;
                }
            } else {
                state.cart = state.cart.filter(item => item.id !== id);
            }
            state.count = state.cart.length;
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        setCurrentTotal(state, action) {
            const { cart, products } = action.payload;

            const total = cart.reduce((sum, cartItem) => {
                const product = products.find(product => product.id === cartItem.id);
                const modificators = cartItem?.modificators 
                    ? cartItem.modificators.reduce((acc, arr) => acc + parseFloat(arr.price), 0).toFixed(2) 
                    : 0;

                return sum + (product ? (Number(modificators) + Number(product.price)) * cartItem.qty : 0);
            }, 0);

            state.cartTotal = total;
        },
        clearCart(state, action) {
            state.cart = [];
            state.cartId = null;
            state.cartTotal = 0;
            state.count = 0;
            localStorage.removeItem('cartId');
            localStorage.removeItem('cart');
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createOrder.pending, state => {state.cartLoadingStatus = 'loading'})
            .addCase(createOrder.fulfilled, (state, action) => {
                state.cartId = action.payload.result;
                localStorage.setItem('cartId', action.payload.result);
                state.cartLoadingStatus = 'idle';
            })
            .addCase(createOrder.rejected, state => {state.cartLoadingStatus = 'error'})
            .addDefaultCase(() => {})
    }
});

export const  { selectAll } = cartAdapter.getSelectors( state => state.cart );

const { reducer, actions } = cartSlice;

export default reducer;

export const {setCurrentTotal, clearCart, setCartId, addToCart, changeQuantity, setCart, setModificator, incrementQuantity, decrementQuantity } = actions;