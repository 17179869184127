import packageJson from '../package.json';

// Получаем версию приложения
const registerSW = async () => {
  const appVersion = packageJson.version;

  // Регистрация Service Worker
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/service-worker.js')
        .then((registration) => {
          // Передаем версию приложения в Service Worker
          if (registration.active) {
            registration.active.postMessage({ type: 'SET_VERSION', version: appVersion });
          }

          // Логика обновления Service Worker
          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker) {
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed' && navigator.serviceWorker.controller) {
                  console.log('New version available. Reloading...');
                  installingWorker.postMessage({ type: 'SKIP_WAITING' });
                  window.location.reload();
                }
              };
            }
          };
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }
};

export { registerSW };
