import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hook/http.hook";


const ordersAdapter = createEntityAdapter();

const initialState = ordersAdapter.getInitialState({
    ordersLoadingStatus: 'idle',
    ordersPreload: false,
    ordersType: 'actual',
    count: 0,
});

export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async ({user, query}) => {
        const { request } = useHttp();

        const response = await request(`orders/${query}/${user}`);

        return response;
    }
);

export const repeatOrder = createAsyncThunk(
    'orders/repeatOrder',
    async ({order}) => {
        const { request } = useHttp();

        const response = await request(`orders/repeat/order/${order}`);

        return response;
    }
)

const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        changeOrderType(state, action) {
            state.ordersType = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrders.pending, state => {state.ordersLoadingStatus = 'loading'})
            .addCase(fetchOrders.fulfilled, (state, action) => {
                const {message, status, result: {type, data}} = action.payload;
                if (status === 'success') {
                    ordersAdapter.setAll(state, data);
                    state.ordersLoadingStatus = 'idle';
                    state.ordersPreload = true

                    if (type === 'actual') {
                        state.count = data.length
                    }
                }
            })
            .addCase(fetchOrders.rejected,  state => {state.ordersLoadingStatus = 'error'})
            .addDefaultCase(() => {})
    }
});

export const  {selectAll} = ordersAdapter.getSelectors(state => state.orders);

const {reducer, actions} = ordersSlice;

export default reducer;

export const { changeOrderType } = actions;