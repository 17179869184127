import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useHttp } from "../../../hook/http.hook";



export const Liqpay = ({rest}) => {
    const navigate = useNavigate();
    const { request } = useHttp();

    // const [hasSimple, setHasSimple] = useState(isSimplePay || false)

    const isCheckoutInitiated = useRef(false);
    const orderStatus = useRef(false);

    useEffect(() => {
        if (!isCheckoutInitiated.current && rest?.data?.data && rest?.data?.signature) { 
            window.LiqPayCheckout.init({
                data: rest.data.data,
                signature: rest.data.signature,
                embedTo: '#liqpay_checkout',
                language: 'ua',
                mode: 'embed'
            }).on("liqpay.callback", function(data){
                console.log(data);
                if (data.status === 'success' && !orderStatus.current) {
                    request(`checkout/update/order/${rest.cartId}/${rest.payment}`, `POST`)
                    .then(data => {
                        if (data.status === 'success') {
                            setTimeout(() => {
                                if (rest.isSimplePay) {
                                    navigate(`/orders`)
                                } else {
                                    navigate(`/success`)
                                }
                            }, 5000)
                        }
                    })
                } 
            }).on("liqpay.ready", function(data){
            }).on("liqpay.close", function(data){
               
                window.LiqPayCheckout.close();
            });
            isCheckoutInitiated.current = true;
        }
    }, [rest]);

    return (
        <div id="liqpay_checkout"></div>
    )
}