import { ReactComponent as ErrorIcon} from "../../assets/images/icons/error.svg"

export const ErrorPage = ({message}) => {
    
    const handleClick = () =>{
        window.location.href = '/menu';
    }

    return (
        <div className="container vh-100 d-flex align-items-center justify-content-center text-center">
            <div className="row align-items-center justify-content-center">
                <div className="col-auto">
                    <ErrorIcon width="150" height="150"/>
                </div>
                <div className="w-100  mt-3"></div>
                <div className="col-auto">
                    <p className="h3">{message}</p>
                </div>
                <div className="w-100 mt-3"></div>
                <div className="col-auto">
                    <div onClick={handleClick} className="btn btn-dark">
                        <span className="fw-bold">На головну</span>
                    </div>
                </div>
            </div>
        </div>
    )
}