import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from "react-redux";


export const RequireAuth = ({children}) => {
    const customer = useSelector(state => state.customer.customer.phone);

    const location = useLocation();

    const navigate = useNavigate();

    useEffect(() => {
        if (customer === null) {
           return  navigate('/signin', { state: {from: location}});
        }
    }, [customer]);

    return children;
}