
import { Suspense, useRef, useEffect, useState } from "react";
import { selectAll as productsSelector } from "../menu/products/productsSlice";

import { Spinner } from "../spinner/Spinner";
import { ProductCard } from "../menu/product-card/product-card";
import { useSelector } from "react-redux";



export const Search = () => {
    const products = useSelector(productsSelector);
    const [query, setQuery] = useState('');
    const [searchResult, setSearchResult] = useState([]);
    const [warning, setWarning] = useState(null);
    const input  = useRef();

    const handleSearch = (e) => {
        e.preventDefault();

        const target = e.target;

        const query = target.value;
        setQuery(query);
    }

    useEffect(() => {
        input.current.focus();
    }, []);

    useEffect(() => {
        const regex = /^[A-Za-zА-Яа-яҐґїЇіІ0-9- ]+$/;
        if (!regex.test(query) && query.length !== 0) {
            setWarning({message: "Введено неприпустимі символи. Будь ласка, використовуйте лише літери та цифри."})
        } else {
            if (query.trim() !== '') {
                setSearchResult(products.filter(item => item.name.toLowerCase().includes(query.toLowerCase())));
            } else {
                setSearchResult([]);
            }
        }
    }, [query]);

    const items = searchResult.map(product => (
        <ProductCard key={product.id} rest={product}/>
    ))

    return (
        <div className="container-fluid">
            <div className="row my-3">
            <form className="mb-3 my-lg-0" >
                <div className="input-group position-relative">
                    <input className="form-control mr-sm-2 pe-4" ref={input} onChange={handleSearch} name="s" type="text" placeholder="Пошук"  />
                    
                </div>
                {warning?.message && 
                    <label htmlFor="s"  className="form-label my-2">
                        <div className="p-2 fs-6 border border-danger text-danger rounded-4"> {warning.message}</div>
                    </label>
                }
            </form>
            </div>
            <div className="row mt-3">
            <Suspense fallback={<Spinner/>}>
                {items}
            </Suspense>
            </div>
        </div>
    )
}
