import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import './fixed-button.css';
import Popover from '../../Popover';

export const FixedButton = ({ title, message, cancelRedirect, ...rest }) => {
    const navigate = useNavigate();
    const [showPopover, setShowPopover] = useState(false);
    const buttonRef = useRef(null);
   

    const handleClick = () => {
        if (cancelRedirect) {
            // if (buttonRef.current) {
            //     const rect = buttonRef.current.getBoundingClientRect();
            //     setPopoverPosition({
            //         top: rect.top - 1000, // Отображаем поповер выше кнопки (на 60px выше)
            //         left: rect.left + rect.width / 2 - 50 // Центрируем поповер по горизонтали
            //     });
            // }
            setShowPopover(true);

            // Убираем поповер через 3.5 секунды
            setTimeout(() => {
                setShowPopover(false);
            }, 3500);
        } else if (rest?.link) {
            navigate(rest.link);
        }
    };

    const handleConfirm = () => {
        setShowPopover(false);
        navigate(rest.link);
    };

    const handleCancel = () => {
        setShowPopover(false);
    };

    return (
        <div className="container fixed-btn-wrap d-flex justify-content-end align-items-center">
            <button ref={buttonRef} {...rest} onClick={handleClick}>
                {title}
                {rest?.count !== 0 &&
                    <div className="position-absolute top-0 translate-middle badge rounded-pill fw-bold">{rest?.count}</div>
                }
            </button>

            {showPopover && (
                <Popover
                    message={message}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    // position={popoverPosition} // Передаем позицию поповера
                />
            )}
        </div>
    );
};