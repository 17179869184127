import { useField, useFormikContext } from "formik"
import { useHttp } from "../../../hook/http.hook";
import { useEffect, useState } from "react";


export const Departments = ({label,children, ...props}) => {
    const {values, setFieldValue} = useFormikContext();
    const { request } = useHttp();
    const [field, meta] = useField(props);
    const [options, setOptions] = useState([]);

    useEffect(() => {
            request(
                'https://api.novaposhta.ua/v2.0/json/',
                "POST",
                JSON.stringify({
                    "apiKey": "3f7aa31949d1c8e1f52cfb8b265feacb",
                    "modelName": "AddressGeneral",
                    "calledMethod": "getWarehouses",
                    "methodProperties": {
                        "FindByString" : "",
                        "CityRef" : values.addressRef,
                        "Page" : "1",
                        "Limit" : "50",
                        "Language" : "UA",
                    }
                }),
                true
            ).then(data => {
                if (data.success) {
                    setOptions(data.data);
                }
            })
    }, [values.addressRef]);



    return (
        <div className="mb-3">
            <label  className="form-label fs-6 fw-bold">{label}</label>
            <div className="input-group mb-3">
                <select className="form-select  fw-bold" 
                    {...field} 
                    {...props}
                >
                    <option value="disabled">Оберіть пунк видачі</option>
                    {options && 
                        options.map((item, index) => (
                            <option key={index}>{item.Description}</option>
                        ))
                    }
                </select>
            </div>
            {meta.touched && meta.error ? (
                <div className="error-message">{meta.error}</div> 
                ) : null
            }
        </div>
    )
}