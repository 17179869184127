import { useEffect } from "react";
import { useSelector } from "react-redux";


export const TermsOfUse  = () => {
    const aboutData = useSelector(state => state.header?.about || {});
    const { origin, pathname } = window.location;
    const segment = pathname.split('/').filter(Boolean)[0] || '';

    const { legalName = '', EDRPOUcode = '', legalAddress = '', companyName = '', bank = '', currentAccount = '',  address = '' } = aboutData;

    const baseUrl = `${origin}/${segment}`;console.log(baseUrl);
    const urlWithoutProtocol = baseUrl.replace(/^(?:https?:\/\/)/, '');

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'visible';
            document.body.style.paddingRight = 0;
        };
    }, []);

    return (
        <div className="container mt-3 mb-5">
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h1 className="text-center">Договір публічної оферти</h1>
                <p>
                    Цей Договір публічної оферти є офіційною та публічною пропозицією укласти договір купівлі-продажу Товару, представленого в закладах (ресторанах) Продавця та/або на веб-сайті: {urlWithoutProtocol} Договір є публічним, тобто відповідно до статей 633, 641, 642 Цивільного кодексу України, його умови є однаковими для всіх Покупців незалежно від їх статусу (фізична особа, юридична особа, фізична особа-підприємець) без надання переваги одному покупцю перед іншим. Факт здійснення Покупцем Замовлення в будь-якій усній та/або будь-якій електронній формі та отримання такого замовлення Продавцем є повним та безперечним прийняттям цього Договору публічної оферти та інформації, викладеної на веб-сайті {urlWithoutProtocol} .
                </p>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                 <h5>1.ЗАГАЛЬНІ ПОЛОЖЕННЯ ТА ТЕРМІНИ.</h5>
                <ul>
                    <li>
                        1.1. Купівля Товарів, що представлені на Веб-сайті Продавця, передбачає згоду особи, яка купує Товар, з правилами, строками, обмеженнями та іншими умовами співпраці, викладеними в Договорі.
                    </li>
                    <li>
                        1.2. Приймаючи умови цього Договору, Покупець підтверджує, що:
                        <ul>
                            <li>
                                інформація, що надається під час замовлення Товарів є правильною та актуальною;
                            </li>
                            <li>
                                надана контактна інформація може бути використана з метою повідомлення про зміни в роботі, акційні пропозиції чи надання іншої інформації, пов’язаної з діяльністю Продавця.
                            </li>
                        </ul>
                        <span>
                            На підставі вищезазначеного уважно ознайомтеся з даним договором (публічною офертою), і якщо Ви не згодні з будь-яким із пунктів оферти, будь ласка, залиште сайт.
                        </span>
                    </li>
                    <li>
                        1.3. У цьому Договорі нижченаведені терміни вживаються у наступному значенні:
                        <ul>
                            <li>
                                «Договір публічної оферти» або «Договір» — цей публічний договір, зразок якого розміщений на Веб-сайті Продавця. Договір передбачає публічну пропозицію Продавця, адресовану будь-якій фізичній особі та/або юридичній особі та/або фізичній особі-підприємцю з метою укласти договір купівлі-продажу на існуючих умовах, зазначених у Договорі.
                            </li>
                            <li>
                                «Товар» — перелік найменувань асортименту, представлений на Веб-сайті Продавця та/або у закладах (ресторанах) Продавця.
                            </li>
                            <li> 
                                «Продавець» — заклад «{companyName}», який реалізує Товар, представлений на Веб-сайті Продавця та безпосередньо у закладі.
                            </li>
                            <li>
                                <p>Юридична інформація Продавця:</p>
                                <ul>
                                    <li>{legalName}</li>
                                    <li>(ІПН/ЄДРПОУ: {EDRPOUcode})</li>
                                    <li>Юридична адреса: {legalAddress}</li>
                                </ul>
                            </li>
                            
                            <li>
                                «Покупець» — фізична особа або фізична особа-підприємець або будь-яка інша юридична особа, що вступила в договірні відносини з Продавцем на умовах, зазначених у Договорі.
                            </li>
                            <li>
                                «Замовлення» — окремі позиції з асортиментного переліку Товару, вказані Покупцем при розміщенні заявки на інтернет-сайті Продавця або замовлені будь-яким іншим способом.
                            </li>
                            <li>
                                «Послуги з доставки Товару» або «Послуги» — безпосередня передача Товару від Продавця до Покупця у місці зазначеному Покупцем та погодженим Продавцем.
                            </li>
                            <li>
                                «Веб-сайт Продавця» — веб-сторінка Продавця, яка знаходиться в мережі Інтернет за адресою: {urlWithoutProtocol}  та через яку Продавець здійснює продаж Товару.
                            </li>
                            <li>
                                «Місце продажу Товару» –  є заклад громадського харчування (ресторан) Продавця.
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>2.ПРЕДМЕТ ДОГОВОРУ.</h5>
                <ul>
                    <li>
                        2.1. Продавець зобов’язується на умовах та в порядку, визначених цим Договором, продати Товар на основі Замовлення, оформленого Покупцем, а Покупець зобов’язується на умовах та в порядку, визначених цим Договором, придбати Товар і сплатити за нього кошти. 
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>3.МОМЕНТ УКЛАДАННЯ ДОГОВОРУ.</h5>
                <ul>
                    <li>
                        3.1. Моментом укладення договору вважається момент замовлення Товару, незалежно від способу замовлення (в тому числі, але не обмежуючись, особисто у закладі (ресторані) Продавця, або здійснення замовлення Товару через телефон вказаний на Веб-сайті Продавця, або з моменту натискання кнопки «ОФОРМИТИ ЗАМОВЛЕННЯ» у розділі «Кошик» через Веб-сайт Продавця) та форми оплати.
                    </li>
                    <li>
                        3.2. Покупець самостійно та за власним бажання зв’язується з продавцем, що означає, що покупець ознайомився з даним договором та погоджується з ним.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>4.ПОРЯДОК ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</h5>
                <ul>
                    <li>
                        4.1. Покупець може оформити замовлення самостійно на Сайті або по телефону з допомогою оператора.
                    </li>
                    <li>
                        4.2. Режим роботи Продавця та час доставки зазначаються на Сайті.
                    </li>
                    <li>
                        <span>4.3. Щоб оформити Замовлення через Сайт Покупцю необхідно:</span>
                        <ul>
                            <li>
                                – обрати Товари, представлені на Сайті, їх кількість, та натиснути «Замовити», Товар буде автоматично додано до розділу «Кошик»;
                            </li>
                            <li>
                                – заповнити дані у відповідній електронній формі замовлення, обрати спосіб оплати Товару;
                            </li>
                            <li>
                                – після закінчення замовлення вибрати функцію «Оформити замовлення».
                            </li>
                        </ul>
                    </li>
                    <li>
                        4.4. Замовлення вважається оформленим та прийнятим до виконання Продавцем у випадку, коли:
                    </li>
                    <li>
                        4.4.1. після завершення Покупцем дій по оформленню замовлення на Сайті, оператор Продавця у телефонному режимі зв’яжеться із Покупцем, за необхідності уточнити деталі замовлення, та підтвердити про прийняття відповідного замовлення в роботу;
                    </li>
                    <li>
                        4.4.2. після здійснення Покупцем замовлення у телефонному режимі, оператор Продавця усно підтвердить Покупцю про прийняття відповідного замовлення в роботу.
                    </li>
                    <li>
                        4.5. У випадку, якщо оператор Продавця не підтвердить Покупцю про прийняття відповідного замовлення в роботу, таке замовлення вважається неоформленим та не підлягає виконанню Продавцем.
                    </li>
                    <li>
                        4.6. Покупець несе повну відповідальність за правильність та достовірність даних, вказаних ним при оформленні замовлення.
                    </li>
                    <li>
                        4.7. Продавець зобов’язується надати Покупцю усю інформацію, що пов’язана з товарами, оформленням та виконанням замовлення, та є необхідною для здійснення вибору та оформлення замовлення.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>5.ЦІНА ТОВАРУ.</h5>
                <ul>
                    <li>
                        5.1. Ціни на Веб-сайті Продавця вказані в національній валюті України (гривні) за одиницю Товару відповідно до існуючого прейскуранта.
                    </li>
                    <li>
                        5.2. Продавець залишає за собою право змінювати ціну Товару до моменту оформлення Замовлення без попередження Покупця.
                    </li>
                    <li>
                        5.3. Загальна ціна Замовлення складається з суми цін за усі Товари, які зазначені у підтвердженому онлайн Замовленні або підтвердженому Замовленні оператором Продавця, з урахуванням актуальної на час оформлення Замовлення ціни Товарів, вказаної на Веб-сайті Продавця, усіх податків та зборів, а також з ціни комплектації Замовлення.
                    </li>
                    <li>
                        5.4. Ціни, вказані на Веб-сайті Продавця, мають ознайомчий характер.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>6.ОПЛАТА ТОВАРУ.</h5>
                <ul>
                    <li>
                        6.1. Ціна Товару вказується в гривнях.
                    </li>
                    <li>
                        6.2. Загальна вартість замовлення складається з вартості усіх Товарів, зазначених у підтвердженому оператором Продавця замовленні Покупця, з урахуванням актуальної на час оформлення замовлення вартості цих Товарів, вказаної на Сайті, усіх податків та зборів, а також з вартості комплектації замовлення.
                    </li>
                    <li>
                        6.3. Загальна вартість замовлення повідомляється оператором Продавця Покупцеві при оформленні замовлення.
                    </li>
                    <li>
                        6.4. Оплата Товару здійснюється готівкою кур’єру при отриманні замовлення, або безготівковим розрахунком, шляхом внесення оплати за допомогою банківських карток на сайті або при отриманні замовлення, або онлайн за допомогою електронного платежу.
                    </li>
                    <li>
                        6.5. Оплата Товарів здійснюється в національній валюті України.

                    </li>
                    <li>
                        6.6. У випадку, якщо Покупцем було обрано безготівкову форму оплати вартості замовлення Товар передається Покупцю лише за умови оплати повної вартості Товару. У такому випадку оплата вартості Товару вважається здійсненою в момент зарахування відповідних грошових коштів на рахунок Продавця.
                    </li>
                    <li>
                        6.7. Продавець має право зупинити виконання замовлення та/або скасувати замовлення в односторонньому порядку у випадку невиконання або неналежного виконання Покупцем обов’язку з оплати відповідного замовлення до моменту доставки Товарів кур’єром за адресою доставки, вказаною Покупцем (у випадку вибору кур’єрської доставки як способу доставки) або до моменту пред’явлення Покупцем вимоги про надання Товарів у місці видачі замовлень (у випадку вибору самовивозу з місця видачі замовлення).
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>7.ДОСТАВКА ТОВАРУ.</h5>
                <ul>
                    <li>
                        7.1. Покупець має право отримати замовлений ним Товар за допомогою самостійного вивозу (самовивіз) із закладу (ресторану) Продавця або скористатися Послугами з доставки Товару у випадку оформлення Замовлення на суму, яка встановлюється Продавцем. Товар може доставлятися Продавцем власними силами або залученими ним третіми особами. При неможливості зв’язатися з Покупцем за вказаним ним при здійсненні Замовлення номером телефону, Замовлення вважається анульованим.
                    </li>
                    <li>
                        7.2. Самовивіз здійснюється Покупцем із закладу (ресторану) Продавця, зазначеного в Замовленні.
                    </li>
                    <li>
                        7.3. Послуги з доставка Товару Покупцю здійснюється за погодженням із Покупцем і за вказаною ним адресою.
                    </li>
                    <li>
                        7.4. Послуги з доставки Товару надаються виключно в час та строки, які вказані на Веб-сайті Продавця, якщо інше не передбачено попередньою домовленістю між Сторонами.
                    </li>
                    <li>
                        7.5. У доставці Товарів можливі затримки за умови настання непередбачених обставини, що сталися не з вини Продавця
                    </li>
                    <li>
                        7.6. Доставка Замовлення Покупцеві здійснюється Продавцем на адресу, вказану у Замовленні.
                    </li>
                    <li>
                        7.7. При прийнятті Замовлення, Покупець зобов’язаний перевірити Товар на відповідність Замовленому і цілісність упаковки. У разі відсутності претензій до доставленого Товару, Покупець оплачує Замовлення (якщо такий спосіб оплати був вибраний Покупцем). Оплата Товару Покупцем свідчить про те, що претензій до Товару не заявлено і Продавець повністю і належним чином виконав свій обов’язок щодо передачі Товару.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>8. Обмеження на використання сайту Користувачем</h5>
                <ul>
                    <li>
                        8.1. Покупець має право відмовитися від отриманого Товару не належної якості після його огляду в момент прийому Товару.
                    </li>
                    <li>
                        8.2. В разі відмови від Товару неналежної якості, ціна якого була сплачена Покупцем шляхом безготівкового розрахунку, Продавець повертає кошти Покупцеві за Товар неналежної якості протягом 14 банківських днів.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>9.ПРАВА ТА ОБОВ’ЯЗКИ СТОРІН</h5>
                <ul>
                    <li>
                        9.1. Продавець зобов’язується не розголошувати будь-яку приватну інформацію Покупця і не надавати доступ до цієї інформації третім особам, за винятком випадків, передбачених чинним законодавством України.
                    </li>
                    <li>
                        9.2. Продавець зобов’язується продавати Товар (за умови його наявності) який представлений на Веб-сайті Продавця, на умовах та в порядку визначеному у цьому Договорі.
                    </li>
                    <li>
                        9.3. Продавець залишає за собою право невиконання Замовлення в разі виникнення форс-мажорних обставин.
                    </li>
                    <li>
                        9.4. Продавець залишає за собою право змінювати Договір в односторонньому порядку до моменту його укладання
                    </li>
                    <li>
                        9.5. Продавець не несе відповідальності за неналежне використання Товарів Покупцем, замовлених через Веб-сайт Продавця.
                    </li>
                    <li>
                        9.6. Продавець має право передати свої права та обов’язки з виконання Замовлень третім особам.
                    </li>
                    <li>
                        9.7. Покупець зобов’язується до моменту оформлення Замовлення на Веб-сайті Продавця ознайомитися зі змістом Договору, умовами оплати і доставки Товару.
                    </li>
                    <li>
                        9.8. Право власності на Замовлення, а також ризик його випадкового пошкодження чи втрати переходять до Покупця з моменту передачі йому Товару.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>10.ВІДПОВІДАЛЬНІСТЬ СТОРІН</h5>
                <ul>
                    <li>
                        10.1. У разі невиконання або неналежного виконання своїх зобов’язань за Договором Сторони несуть відповідальність, передбачену чинним законодавством та цим Договором.
                    </li>
                    <li>
                        10.2. Продавець не несе відповідальності за шкоду, заподіяну Покупцеві внаслідок неналежного використання придбаних ним Товарів.
                    </li>
                    <li>
                        10.3. Покупець несе відповідальність за правильність внесених даних до форми Замовлення.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>11.ПОРЯДОК ВИРІШЕННЯ СПОРІВ</h5>
                <ul>
                    <li>
                        11.1. Всі спори, які можуть виникнути щодо цього Договору або у зв’язку з його виконанням вирішуються Сторонами шляхом переговорів. Досудовий порядок врегулювання спору є обов’язковим.
                    </li>
                    <li>
                        11.2. Всі спірні питання розглядаються за наявності письмової претензії, що направлена рекомендованим листом засобами поштового зв’язку.
                    </li>
                    <li>
                        11.3. У випадку, якщо Сторони не зможуть досягти згоди по спірним питанням шляхом переговорів, ці питання підлягають вирішенню у судовому порядку згідно з діючим законодавством України.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>12.ФОРС-МАЖОРНІ ОБСТАВИНИ.</h5>
                <ul>
                    <li>
                        12.1. Сторони звільняються від відповідальності за невиконання чи неналежне виконання зобов’язань за умовами Договору на час дії обставин непереборної сили, які безпосередньо впливають на виконання Сторонами Договору. Під непереборною силою розуміються надзвичайні і непереборні за таких умов обставини, що перешкоджають виконанню своїх зобов’язань Сторонами за Договором. До них належать стихійні явища (землетруси, повені і т. ін.), обставини суспільного життя (війна, оголошення воєнного стану, проведення військових операцій, інші воєнні дії, надзвичайні стани, страйки, епідемії, пандемії тощо), заборонні заходи державних органів (заборона перевезень, валютні обмеження, міжнародні санкції, заборони на торгівлю тощо).

                        <p className="mt-2">
                            Протягом цього часу Сторони не мають взаємних претензій і кожна зі Сторін приймає на себе свій ризик за наслідками форс-мажорних обставин.
                        </p>
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>13.ІНФОРМАЦІЯ ТА ЇЇ ВИКОРИСТАННЯ.</h5>
                <ul>
                    <li>
                        <span>
                            13.1. Продавець відповідно до Закону України «Про захист персональних даних» збирає та опрацьовує персональні дані Покупця (а саме: прізвище, ім’я, по батькові Покупця; адреса доставки; контактні телефони, адреса електронної пошти тощо), а Покупець дає Продавцю свою беззаперечну згоду на опрацювання та зберігання його персональних даних, з метою:
                        </span>
                        <ul>
                            <li>
                                виконання умов Договору;
                            </li>
                            <li>
                                доставки Покупцю замовленого ним Товару.
                            </li>
                        </ul>
                    </li>
                    <li>
                        13.2. Здійснюючи замовлення Товару через Веб-сайт Продавця, Покупець дає згоду на збір та опрацювання такої інформації.
                    </li>
                    <li>
                        13.3. Доступ до персональних даних Покупця мають лише особи, які безпосередньо залучені до виконання Замовлення.
                    </li>
                    <li>
                        13.4. Продавець зобов’язується: дотримуватися конфіденційності стосовно персональних даних Покупця; не допускати спроби несанкціонованого використання персональних даних Покупця третіми особами; виключити доступ до персональних даних Покупця осіб, що не мають безпосереднього відношення до виконання Замовлення, окрім випадків, передбачених чинним законодавством України.
                    </li>
                    <li>
                        13.5 Продавець має право відправляти інформаційні, в тому числі рекламні повідомлення, на мобільний телефон Покупця з його згоди. Покупець має право відмовитися від отримання рекламної та іншої інформації без пояснення причин відмови, направивши письмову заяву про відмову від отримання рекламної та іншої інформації Продавцю за адресою, вказаною на Веб-Сайті. Сервісні повідомлення, що інформують Покупця про замовлення і етапи його обробки, відправляються автоматично і не можуть бути відхилені Покупцем.
                    </li>
                    <li>
                        13.6. Продавець не несе відповідальності за відомості, надані Покупцем на Веб-Сайті в загальнодоступній формі.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2">
                <h5>14. ДОДАТКОВІ УМОВИ</h5>
                <ul>
                    <li>
                    14.1. Веб-Сайт може бути тимчасово частково або повністю недоступний внаслідок проведення технічних, профілактичних або інших робіт, або з будь-яких інших причин технічного характеру.
                    </li>
                    <li>
                    14.2. З усіх питань, які не передбачені умовами даної оферти, Покупець та Продавець керуються чинним законодавством України.
                    </li>
                    <li>
                    14.3. У випадку встановлення особливих умов стосовно асортименту, вартості Товарів, інших умов замовлення у зв’язку з проведенням акцій, тощо – такі дії не будуть вважатися порушенням п. 2 ст. 633 Цивільного кодексу України.
                    </li>
                </ul>
            </div>
            <div className="tou-list border border-5 rounded mb-3 p-2 d-flex flex-column">
                <div className="d-flex mb-2">
                    <span className="me-2 fw-bold">Юридична назва:</span>
                    <span>{legalName}</span>
                </div>
                <div className="d-flex mb-2">
                    <span className="me-2 fw-bold">Юридична адреса:</span>
                    <span>{legalAddress}</span>
                </div>
                <div className="d-flex mb-2">
                    <span className="me-2 fw-bold">ЄДРПОУ:</span>
                    <span>{EDRPOUcode}</span>
                </div>
                <div className="d-flex mb-2">
                    <span className="me-2 fw-bold">IBAN:</span>
                    <span>{currentAccount}</span>
                </div>
                <div className="d-flex mb-2">
                    <span className="me-2 fw-bold">В банку:</span>
                    <span>{bank}</span>
                </div>
            </div>
        </div>
    )
}