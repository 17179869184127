import { motion } from "framer-motion";

export const LoadingDots = () => {
  const variants = {
    start: { opacity: 1 },
    end: { opacity: 0 }
  };

  return (
    <div>
      Завантаження
      {[...Array(3)].map((e, i) => (
        <motion.span
          key={i}
          variants={variants}
          initial="start"
          animate="end"
          transition={{
            duration: 1,
            repeat: Infinity,
            repeatDelay: 0.5 * i
          }}
        >
          .
        </motion.span>
      ))}
    </div>
  );
};
