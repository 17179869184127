import React from 'react';
import { useDispatch, useSelector } from "react-redux"
import { CSSTransition } from 'react-transition-group';
import { fetchSettings, showModal } from "./headerSlice"
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { About } from "../../about/about";

import { ReactComponent as  PhoneIcon} from "../../../assets/images/icons/phone.svg";
import { ReactComponent as  SearchIcon}from "../../../assets/images/icons/search.svg";
import { ReactComponent as  AboutIcon} from "../../../assets/images/icons/about.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/icons/arrow.svg";
import { ReactComponent as BurgerIcon } from "../../../assets/images/icons/burger_menu.svg";


import './header.css';
import { MenuItems } from './MenuItems';

const Header = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const bgCompanyName = useSelector(state => state.header.about?.bgCompanyName) || '';
    const сompanyName = useSelector(state => state.header.about?.companyName) || '';
    const ordersCount = useSelector(state => state.orders.count); 
    const phone = useSelector(state => state.header.about?.phone) || null;
    const bg = useSelector(state => state.header.settings.banner);
    

    const backHandle = () => {
        return navigate('/');
    }
    const isRootPath = pathname === '/';

    return (
        <>
            <header className={`position-relative ${!isRootPath && 'min'}`}>
                {isRootPath ? 
                    (
                        <>
                            <div className="banner">
                                <img src={bg} alt="" />
                            </div>
                            <nav className="navbar navbar-dark">
                                <div className="container-fluid justify-content-end">
                                    <Link to={`/search`} className="search-open me-2">
                                        <SearchIcon/>
                                    </Link>
                                    <div>
                                        <a href={`tel:${phone && phone}`}>
                                            <PhoneIcon/>
                                        </a>
                                    </div>
                                    <button className="navbar-toggler position-relative" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                                            {ordersCount !== 0 && 
                                                <span class="position-absolute  top-0 p-1 bg-danger border border-light rounded-circle menu-badge" style={{width: 3, height: 3}}>
                                                    <span class="visually-hidden">New alerts</span>
                                                </span>
                                            }
                                            <BurgerIcon/>
                                    </button>
                                    <div className="offcanvas offcanvas-start text-bg-dark" tabIndex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                                        <div className="offcanvas-header">
                                            <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">{сompanyName && сompanyName}</h5>
                                        </div>
                                        <div className="offcanvas-body">
                                            <MenuItems/>
                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </>
                    )

                    :

                    (
                        <div className='position-absolute top-50 start-0 translate-middle-y back' onClick={backHandle}>
                            <ArrowIcon/>
                            <span>Назад</span>
                        </div>
                    )
                }
                <div className="company-name">{bgCompanyName && bgCompanyName}</div>
            </header>
            {/* <CSSTransition
                in={showAbout}
                timeout={300}
                classNames="modal"
                unmountOnExit
            >
                <About/>
            </CSSTransition> */}
        </>
    )
}

export default React.memo(Header);