
import './popover.css';

const Popover = ({ message, onConfirm, onCancel }) => {
    return (
        <div className="popover">
            <div className="popover-content">
                <p>{message}</p>
                <div className="popover-buttons">
                    <button className="popover-button cancel" onClick={onCancel}>
                        Зрозуміло
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Popover;
