import { Link } from 'react-router-dom';

import {ReactComponent as FoodIcon} from "../../../assets/images/icons/food.svg";

export const CardMobile = ({children, rest, img}) => {

    const description =rest.description || '';

    return (
        <div className={`product-card position-relative mb-1 py-2 border-bottom`}>
            <div className="d-flex">
                <div className="product-image">
                    { img ?
                        <img src={img} alt={rest.name} /> :
                        <FoodIcon className='default-img'/>
                    }
                </div>
                <div className="w-100 ">
                    <div className="row ms-0">
                        <div className="product-head d-flex justify-content-between ps-0">
                            <div className="product-title pe-2"><span>{rest.name}</span></div>
                            <div className="product-price text-end"><span>{rest.price}</span></div>
                        </div>
                    </div>
                    <div className="row  ms-0 justify-content-between">
                        <div className="col col-md-10 ps-0 pe-0">
                            <div className="product-description">
                                <span>
                                    {description}
                                </span>
                            </div>
                        </div>
                        <div className="col-5 w-auto ms-1 col-md-2 ps-0 ">
                            <div className="d-flex flex-column justify-content-between align-items-end">
                                    {children &&
                                        children 
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}