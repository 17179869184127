import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Contacts } from "./contacts";
import { Schedule } from "./schedule";

import { ReactComponent as  AboutIcon} from "../../../assets/images/icons/about.svg";
import {ReactComponent as ArrowIcon} from "../../../assets/images/icons/arrow.svg";
import {ReactComponent as TermsIcon} from "../../../assets/images/icons/terms_of_use.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/images/icons/history.svg";
import { APP_VERSION } from "../../..";

export const MenuItems = () => {
    const aboutData = useSelector(state => state.header?.about || {});
    const schedule = useSelector(state => state.header.schedule);
    const ordersCount = useSelector(state => state.orders.count);

    return (
        <>
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                {ordersCount !== 0  &&
                    <li className="nav-item mb-2">
                        <Link to={`/orders`} className="position-relative text-decoration-none fw-bold">
                            <span class="position-absolute  top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle" style={{width: 3, height: 3}}>
                                <span class="visually-hidden">New alerts</span>
                            </span>
                            Актуальні замовлення
                        </Link>
                    </li>
                }
                {Object.keys(aboutData).length !== 0 &&
                    <li className="nav-item mb-2">
                        <Contacts rest={aboutData}/>
                    </li>
                }
                {schedule && schedule.length !== 0 && 
                    <li className="nav-item mb-2">
                        
                        <Schedule rest={schedule}/>
                    </li>
                }
                <li className="nav-item border-bottom pb-2 mb-2">
                        <Link to={`/orders/completed`} style={{ textDecoration: 'none' }}>
                                <div className="d-flex align-items-center">
                                    <HistoryIcon/>
                                    <p className="fw-bold m-0 ms-2 me-auto">Архів замовлень</p>
                                    <ArrowIcon/>
                                </div>
                        </Link>
                </li>
                {Object.keys(aboutData).length !== 0 &&
                    <li className="nav-item border-bottom pb-2 mb-2">
                        <Link to={`/aboutUs`} state={aboutData} style={{ textDecoration: 'none' }}>
                                <div className="d-flex align-items-center">
                                    <AboutIcon/>
                                    <p className="fw-bold m-0 ms-2 me-auto">Про компанію</p>
                                    <ArrowIcon/>
                                </div>
                        </Link>
                    </li>
                }
                <li className="nav-item border-bottom pb-2 mb-2">
                    <div className="d-flex align-items-center">
                        <TermsIcon/>
                        <Link to={'/terms-of-use'} state={aboutData}  className="fw-bold m-0 ms-2 me-auto text-decoration-none">
                            Договір публічної оферти
                        </Link>
                        <ArrowIcon/>
                    </div>
                </li>
                <li className="nav-item border-bottom pb-2 mb-2">
                    <div className="d-flex align-items-center">
                        <TermsIcon/>
                        <Link to={'/cookie-policy'}  className="fw-bold m-0 ms-2 me-auto text-decoration-none">
                            Умови використання файлів Cookie
                        </Link>
                        <ArrowIcon/>
                    </div>
                </li>
                {/* <li className="nav-item border-bottom pb-2 mb-2">
                    <div className="d-flex align-items-center">
                        <TermsIcon/>
                        <a className="text-decoration-none fw-bold m-0 ms-2 me-auto" target="_blank" href={`${window.location.origin}/menu/oferta.php`}>Договір публічної оферти</a>
                        <ArrowIcon/>
                    </div>
                </li> */}
            </ul>
            <div className="d-flex align-items-center justify-content-center mt-auto">
                <span className="me-2">ver</span>
                <span>{APP_VERSION}</span>
            </div> 
        </>
    )
}