import { useEffect } from "react";
import { setTmpId, likes as getLikes } from "../user/customerSlice";
import { useDispatch, useSelector } from "react-redux";

import { Categories } from "./categories/categories"
import { Products } from "./products/products"
import { SingleProduct } from "./single-product/single-product";

import './categories/categories.css';
import './menu.css';

function generateId(length = 10) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
  
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
  
    return result;
}


export const Menu = () => {
    const dispatch = useDispatch();
    const singleProduct = useSelector(state => state.products.single);
    const customer = useSelector(state => state.customer.customer.phone);
    const tmpId = useSelector(state => state.customer.tmpId);

    useEffect(() => {
        if (!customer && !tmpId) {
            const newTmpId = generateId(10);
            dispatch(setTmpId(newTmpId));
        }
    }, [customer, tmpId, dispatch]);

    useEffect(() => {
        let tmpUser;

        if(customer) {
            console.log(customer);
            tmpUser = customer;
        } else {
            tmpUser = tmpId;
        }

        dispatch(getLikes({user: tmpUser}))
    }, [customer]);



    return (
        <>
            <Categories/>
            <Products/>
            {!!singleProduct &&
                <SingleProduct/>
            }
        </>
    )
}