import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hook/http.hook";

const checkoutAdapter = createEntityAdapter();

const initialState = checkoutAdapter.getInitialState({
    paymentLoadingStatus: 'idle',
    data: null,
});

export const liqpay = createAsyncThunk(
    'checkout/liqpay',
    async ({order}) => {
        const { request } = useHttp();
        const response = await request(`checkout/liqpay/data/${order}`);
        return response;
    }
);

const checkoutSlice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(liqpay.pending, state => {state.paymentLoadingStatus = 'loading'})
            .addCase(liqpay.fulfilled, (state, action) => {
                state.paymentLoadingStatus = 'idle'
                state.data = action.payload.result;
            })
            .addCase(liqpay.rejected, state => {state.paymentLoadingStatus = 'error'})
    }
});

const { reducer } = checkoutSlice;
export default reducer;
