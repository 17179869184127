import { useEffect } from "react";
import { Liqpay } from "./payments/liqpay";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { liqpay } from "./checkoutSlice";
import { Spinner } from "../spinner/Spinner";

export const Checkout = () => {
    const { state: { payment, cartId, isSimplePay } } = useLocation();
    const data = useSelector(state => state.checkout.data);
    const paymentLoadingStatus = useSelector(state => state.checkout.paymentLoadingStatus);;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (payment === "liqpay") {
            dispatch(liqpay({ order: cartId }));
        }
    }, []);

    if (payment === "liqpay") {
        if (paymentLoadingStatus === 'loading') {
            return <Spinner/>
        } else if (paymentLoadingStatus === 'idle') {
            return <Liqpay rest={{ data: data, payment, cartId, isSimplePay}} />;
        } 
        else if (paymentLoadingStatus === 'error') {
            let error = new Error('На разі данний спосіб оплати недоступний, оберіть інший або спробуйте пізніше');
            error.details = {
                message: 'На разі данний спосіб оплати недоступний, оберіть інший або спробуйте пізніше',
                error: 'На разі данний спосіб оплати недоступний, оберіть інший або спробуйте пізніше',
                type: 'liqpay',
            };
            throw error;
        }
    } else if (payment === "mono") {
        // handle mono payment
    } else {
        let error = new Error('Невідомий спосіб оплати');
        error.details = {
            message: 'Невідомий спосіб оплати',
            type: 'unknown',
        };
        throw error;
    }
};
