import { useDispatch } from 'react-redux';
import { useInView } from "framer-motion";
import { activeCategory } from "./categoriesSlice";
import { useEffect, useRef } from "react";

export const Category = ({rest}) => {
    const dispatch = useDispatch();
    const ref = useRef(null);

    const inView = useInView(ref, {
        margin: `-10% 0px -10% 0px`,
    });

    useEffect(() => {
        if (inView) {
            dispatch(activeCategory(rest.id)); 
        }
    }, [inView, rest.id, dispatch])

    return (
        <div ref={ref}  id={`category-${rest.id}`} className="category">
            {rest.name}
        </div>
    )
}
