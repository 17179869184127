import { Outlet, useLocation } from "react-router-dom"
import { AnimatePresence, motion } from "framer-motion";

import Header from "../navigation/header/header"
import { Style } from "./style"
import { Footer } from "../navigation/footer/footer"

import './../cart/cart.css';
import { AgeVerification } from "../template/popup/age-verification";

export const Layout = () => {
    const {key} = useLocation();

    return (
        <>
            <Style/>
            <AnimatePresence mode="wait">
               <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 0.7} }}
                key={key}
               >
                    <Header/>
                        <div className="container mb-5">
                            <Outlet/>
                        </div>
                    <Footer/>
               </motion.div>
            </AnimatePresence>
            <AgeVerification/>
        </>
    )
}