import { useEffect, useState } from "react";
import { useCookie } from "../../../hook/cookie.hook";
import { useNavigate } from "react-router-dom";

export const AgeVerification = () => {
    const { setCookie, getCookie } = useCookie();
    const navigate = useNavigate();
    const [answer, setAnswer] = useState(false);
    const [adult, setAdult] = useState(getCookie('isAdult') || 0);

    useEffect(() => {
        if (!adult && answer) {
            setCookie('isAdult', '1');
            setAdult(1); 
        }
    }, [answer, adult, setCookie]);

    if (adult) {
        return null;
    }

    const handleClick = (param) => {
        if (param) {
            setAnswer(param);
        } else {
            navigate(-1);
        }
    }

    return (
        <div className="modal fade show" style={{display: 'block'}}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body text-center">
                        <span className="fw-bold">Вам виповнилося 18 років?</span>
                    </div>
                    <div className="modal-footer">
                        <button type="button" onClick={() => handleClick(false)} className="btn btn-secondary fw-bold" data-bs-dismiss="modal">Ні</button>
                        <button type="button" onClick={() => handleClick(true)} className="btn btn-primary fw-bold">Так</button>
                    </div>
                </div>
            </div>
        </div>
    );
};