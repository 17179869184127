export const useCookie = () => {
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  }
  
  const setCookie = (name, value, options = {}) => {
      let expires = options.expires;
      if (typeof expires === 'number') {
          expires = new Date(Date.now() + expires * 1000);
      }

      let cookieString = `${name}=${value}; path=${options.path || '/'}`;
      
      if (expires) {
          cookieString += `; expires=${expires.toUTCString()}`;
      }

      if (options.domain) {
          cookieString += `; domain=${options.domain}`;
      }

      document.cookie = cookieString;
  }

  return { getCookie, setCookie };
}