import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useHttp } from '../../../hook/http.hook';
import Select from 'react-select';

export const PostAddress = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const [options, setOptions] = useState([]);
  const { request } = useHttp();
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(inputValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  useEffect(() => {
    if (debouncedValue) {
      request(
        'https://api.novaposhta.ua/v2.0/json/',
        'POST',
        JSON.stringify({
          "apiKey": "e41b334aca44743ac894c1079f4df565",
          "modelName": "AddressGeneral",
          "calledMethod": "searchSettlements",
          "methodProperties": {
              "CityName": debouncedValue,
              "Limit": "50",
              "Page": "1"
          }
        }),
        true
      ).then(
        data => {
          if (data.success) {
            const addresses = data.data[0].Addresses;
            const newOptions = addresses.map(item => ({
              value: item.Present,
              ref:  item.DeliveryCity,
              label: item.Present,
            }));
            setOptions(newOptions);
          }
        }
      );
    }
  }, [debouncedValue]);

  const handleChange = (selectedOption) => {
    setFieldValue(field.name, selectedOption ? selectedOption.value : '');
    setFieldValue('addressRef', selectedOption ? selectedOption.ref : '');
  }

  const handleInputChange = (value) => {
    setInputValue(value);
  }

  return (
    <div className='mb-2'>
      <label htmlFor={props.id || props.name} className="form-label fs-6 fw-bold">{label}</label>
      <Select
        {...props}
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={options}
        value={options.find(option => option.value === field.value) || ''}
      />
      {meta.touched && meta.error ? (
          <div className="error-message">{meta.error}</div> 
          ) : null
      }
    </div>
  );
};
