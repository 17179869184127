import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../hook/http.hook";

const customer = JSON.parse(localStorage.getItem('customer'));
const tmpId = localStorage.getItem('tmpId');console.log(tmpId)

const customerAdapter = createEntityAdapter();

const initialState = customerAdapter.getInitialState({
    customer: {
        name: customer?.name || null,
        phone: customer?.phone || null,
        address: customer?.address || null,
        likes: []
    },
    tmpId: tmpId || null,
});

export const likes = createAsyncThunk(
    'customer/likes',
    async ({user}) => {
        const { request } = useHttp();
        const response = await request(`user/likes/${user}`);

        return response;
    }
);

export const user = createAsyncThunk(
    'customer/user',
    async ({user}) => {
        const { request } = useHttp();
        
        const response = await request(`user/get/${user}`);

        return response;
    }
);

export const addUser = createAsyncThunk(
    'customer/addUser',
    async ({user}, { getState }) => {
        const { request } = useHttp();
        const state = getState();
        const tmpId = state.customer.tmpId;
        const response = await request(
            `user/add`,
            `POST`,
            JSON.stringify({user, tmpId})
        );
        return response;
    }
);

export const signin = createAsyncThunk(
    'customer/signin',
    async ({user}, { getState }) => {
        const { request } = useHttp();
        const state = getState();
        const tmpId = state.customer.tmpId;
        const response = await request(`user/signin/${user}/${tmpId}`)
        return response;
    }
);


export const updateLike = createAsyncThunk(
    'customer/updateLike',
    async ({user, id}) => {
        const { request } = useHttp();

        const response = await request(`user/like/udpate/${user}/${id}`);

        return response;
    }
)

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setСustomer(state, action) {
            state.customer.name = action.payload.name;
            state.customer.phone = action.payload.phone;
            state.customer.address = action.payload.address;
            localStorage.setItem('customer', JSON.stringify({...action.payload}))
        },
        setTmpId(state, action) {
            state.tmpId = action.payload
            localStorage.setItem('tmpId', action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(likes.fulfilled, (state, action) => {
                state.customer.likes = action.payload.result
            })
            .addCase(user.fulfilled, (state, action) => {
               if (action.payload.status === 'success') {
                    state.customer.name = action.payload.result.name
                    state.customer.phone = action.payload.result.phone
                    state.customer.address = action.payload.result.address;
               }
            })
    }
});

export const { selectAll } = customerAdapter.getSelectors(state => state.customer);

const { reducer, actions } = customerSlice;

export default reducer;

export const {setСustomer, setTmpId} = actions;