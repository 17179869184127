import React from "react";
import { Select } from "../../components/shipping/inputs/select";
import { format, addDays, eachDayOfInterval, parse, isPast, isToday, eachMinuteOfInterval} from 'date-fns';
import { uk } from 'date-fns/locale';

const getDays = (schedule) => {
    const now = new Date();
    
    const end = addDays(now, 3);
    
    const daysRange = eachDayOfInterval({
    start: now,
    end: end
    })

    return daysRange.map((day, index) => {
        const optionTitle = format(day, 'eeee', {locale: uk});
        const optionValue = format(day, 'dd-MM-yyyy', {locale: uk});

        const workHours = schedule.find(hour => hour.hasOwnProperty(optionTitle));

        if (workHours && isToday(day)) {
            const closeTime = parse(workHours[optionTitle].end, 'HH:mm', new Date());
            if (isPast(closeTime)) {
                return null
            }
        }

        return (
            <option key={index} value={optionValue}>{optionTitle.charAt(0).toUpperCase() + optionTitle.slice(1)}</option>
        )
    }).filter(Boolean);

}

const getHours = (schedule, currentDate) => {

    const date = parse(currentDate, 'dd-MM-yyyy', new Date());

    const day = format(date, 'eeee', {locale: uk});
    const timeRange = schedule.find(hour => hour.hasOwnProperty(day));
    let  start = parse(timeRange[day].start, 'HH:mm', new Date());
    const end = parse(timeRange[day].end, 'HH:mm', new Date());

    if (isToday(date)) {

        start = new Date();
    }
    
    const minutes = eachMinuteOfInterval({ start, end });
    
    const hoursRange = minutes.filter((minute) => {
        const formattedTime = format(minute, 'mm');
        return formattedTime === '00' || formattedTime === '30';
    });

    return hoursRange.map((hour, index) => {
        const current = format(hour, 'HH:mm');
        return (
            <option key={index} value={current}>{current}</option>
        )
    })
}

export const ShippingHandler = ({schedule, date}) => {

    const daysOptions = getDays(schedule);

    let hoursOptions = null;

    if (date !== '' && date !== 'today')
    {
        hoursOptions = getHours(schedule, date)
    }

    return (
        <>
             <Select
                label='Оберіть день'
                id='shipp-day'
                name='shipp-day'
            >
                <option value="today" disabled>Оберіть день</option>
                {daysOptions}
            </Select>
            <Select
                label='Оберіть час'
                id='shipp-hour'
                name='shipp-hour'
            >
                <option value="today" disabled>Оберіть час</option>
                {hoursOptions}
            </Select>
        </>
    )
}