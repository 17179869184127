import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SuccessIcon } from "../../assets/images/icons/success.svg";
import { useEffect } from "react";
import { clearCart } from "../cart/cartSlice";
import { addUser, setСustomer} from "../user/customerSlice";
import { Link } from "react-router-dom";
import { useHttp } from "../../hook/http.hook";

export const Success = () => {
    const { request } = useHttp();
    const dispatch = useDispatch();
    const cart = useSelector(state => state.cart.cart);

    const shipping = JSON.parse(localStorage.getItem('shipping'));
    
    delete shipping?.comment;
    delete shipping?.shippingDate;
    delete shipping?.shippingMethod;

    useEffect(() => {
        if (cart.length !== 0) {
            dispatch(addUser({ user: shipping }));
            dispatch(setСustomer(shipping));
            request('send/notification');
            dispatch(clearCart());
        }
    }, []);
    

    return (
        <div className="container d-flex align-items-center justify-content-center text-center mt-5">
            <div className="row align-items-center justify-content-center">
                <div className="col-auto">
                    <SuccessIcon />
                </div>
                <div className="w-100 mt-3"></div>
                <div className="col-auto">
                    <p className="h4">Ваше замовлення прийнято!</p>
                    <p className="h5">Очікуйте дзвінка адміністратора або кур'єра</p>
                </div>
                <div className="w-100 mt-3"></div>
                <div className="col-auto">
                    <Link to={'/orders'} className="btn">
                        До замовлень
                    </Link>
                </div>
            </div>
        </div>
    );
}
