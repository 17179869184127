import { useLocation, useNavigate,  } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchOrders, repeatOrder, selectAll as ordersSelectors, changeOrderType } from "./ordersSlice";
import { setCart } from "../cart/cartSlice";

import { PayBtn } from "./elements/pay-btn";
import { RepeatBtn } from "./elements/repeat-btn";
import { useEffect } from "react";
import { Spinner } from "../spinner/Spinner";


export const OrdersList = () => {
    const payConfirmation = useSelector(state => state.header.settings.payConfirmation);
    const payment = useSelector(state => state.header.settings.payment);
    const orders = useSelector(ordersSelectors);console.log(orders);    
    const orderType = useSelector(state => state.orders.ordersType);
    const customer = useSelector(state => state.customer.customer.phone);
    const ordersLoadingStatus = useSelector(state => state.orders.ordersLoadingStatus);
    const dispatch = useDispatch();
    const {pathname} = useLocation();
   

    useEffect(() => {
        dispatch(fetchOrders({user: customer, query: orderType}))
    }, [orderType]);

    useEffect(() => {
        if (orderType !== 'completed') {
             const interval = setInterval(() => {
                dispatch(fetchOrders({user: customer, query: orderType}))
             }, 15000);
             return () => clearInterval(interval);
        }
    }, [orderType]);

    const navigate = useNavigate();

    const handlePayClick = (values) => {

        return navigate('/checkout', {state: {isSimplePay: true, payment: values.method, cartId: values.orderId}});
    }

    const handleRepeatClick = (orderId) => {
        dispatch(repeatOrder({order: orderId}))
        .then(data => {
            if (data.payload.status === 'success') {
                localStorage.setItem('cartId', data.payload.result);
                dispatch(setCart(data.payload.result))
                dispatch(changeOrderType('actual'));
                return navigate('/cart');
            }
        })
    }

     if(ordersLoadingStatus === 'loading') 
    {
        return (
            <div className="row mt-2">
                <Spinner/>
            </div>
        )
    }

    const result = orders.map((item) =>{
        let  status; 

        if (pathname === '/orders/completed') {
            if (Number(item.rejected))
            {
                status = {
                    class: 'text-danger',
                    text: 'Відмінено'
                }
            } else {
                status = {
                    class: 'text-success',
                    text: 'Виконано',
                    repeatBtn: true,
                }
            }
        } else {
            if (Number(item?.accessStatus)) {
                 status = {
                    class: 'text-primary',
                    text: 'Виконується',
                    payBtn: true,
                }
            } else {
                 status = {
                    class: 'text-info-emphasis',
                    text: 'Очікує'
                }
            }
        }
        console.log(checkProducts(item.orders));
        return    (
            <div key={item.id} className="row mt-2">
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item">
                        <h2 className="accordion-header">
                        <button className="accordion-button collapsed custom" type="button" data-bs-toggle="collapse" data-bs-target={`#order-${item.id}`}>
                            <span className='order-title'>Замовлення: №{item.id}</span><span className={`ms-auto text-end status-text ${status?.class}`}>{status?.text}</span>
                        </button>
                        </h2>
                        <div id={`order-${item.id}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Товар</th>
                                            <th scope="col">Кількість</th>
                                            <th scope="col">Cума</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.orders.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.name}</td>
                                                <td>{item.qtt}</td>
                                                <td className='text-end'>{item.amount}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                    <tfoot>
                                        <tr style={{borderBottom: 'none', borderColor: 'white'}}>
                                            <td colSpan={2}>Загальна сума</td>
                                            <td className='text-end'>{item.amount}</td>
                                        </tr>
                                        {status?.payBtn && payConfirmation && Number(payConfirmation) !== 0 && checkProducts(item.orders) &&
                                            <PayBtn props={{payment, orderId: item.id, paid: item.paid}} onHandleClick={handlePayClick}/>
                                        }
                                        {status?.repeatBtn &&
                                            <RepeatBtn onHandleClick={() => handleRepeatClick(item.id)}/>
                                        }
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    })

    return result
}

function checkProducts(products) {
    return products.every(product => {
        if (Number(product.gravimetric) === 1) {
            return Number(product.status) === 5;
        } else {
            return Number(product.status) === 2 || Number(product.status) === 5;
        }
    });
}