import {useMemo} from "react";
import {useSelector } from "react-redux";
import {selectAll as productsSelector} from "./productsSlice";
import { selectAll as categoriesSelector } from "../categories/categoriesSlice";
import { Category } from "../categories/category";
import { ProductCard } from "../product-card/product-card";

export const Products = () => {
    const products = useSelector(productsSelector);console.log(products)
    const categories = useSelector(categoriesSelector);
    const renderProducts = useMemo(() => {
        return categories.map(category => (
            <div className="row" key={category.id}>
                 <div className="col-12">
                        <Category rest={category} />
                </div>
                {products
                    .filter(product => product.category_id === category.id)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((product, index) => (
                        <ProductCard key={product.id} rest={product} index={index}/>
                    ))
                }
            </div>
        ));
    }, [categories, products]);
    

    return renderProducts;
};
