export const useHttp = () => {
    const _apiBase = process.env.NODE_ENV === 'production' ? '/api/v1/' : 'http://john-gaspar/api/v1/';

    const request = async (url, method = 'GET', body = null, otherAPI = false,  headers = {'Content-Type': 'application/json'}) => {
        try {
            let response;
            if (otherAPI) {
                response = await fetch(url, {method, body, headers});
            } else {
                response = await fetch(_apiBase + url, {method, body, headers});
            }
            

            if (!response.ok) {
                let error = new Error(`Виникла непередбачувана помилка, повторіть запит або спробуйте пізніше.`);
                throw error;
            }
            
            const data = await response.json();
        
            return data;
        } catch(e) {
            let error = new Error('Виникла непередбачувана помилка, повторіть запит або спробуйте пізніше.');
            error.details = {
                message: 'Виникла непередбачувана помилка, повторіть запит або спробуйте пізніше.',
                error: 'Виникла непередбачувана помилка, повторіть запит або спробуйте пізніше.',
                type: 'any',
            };
            throw error;
        }
    };

    return {request};
}
