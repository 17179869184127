import { useDispatch, useSelector } from "react-redux"
import { selectAll as categoriesSelector, fetchCategories, changeCategory, activeCategory } from "./categoriesSlice"
import { useEffect, useRef } from "react";

export const Categories = () => {
    const categories = useSelector(categoriesSelector);
    const active = useSelector(state => state.categories.activeCategory);
    const categoriesContainerRef = useRef(null);
    const activeRef = useRef(null);

    useEffect(() => {
        if (activeRef.current && categoriesContainerRef.current) {
            const container = categoriesContainerRef.current;
            const activeElement = activeRef.current;
            const containerRect = container.getBoundingClientRect();
            const activeRect = activeElement.getBoundingClientRect();

            if (activeRect.left < containerRect.left || activeRect.right > containerRect.right) {
                container.scrollLeft += activeRect.left - containerRect.left - (containerRect.width / 2 - activeRect.width / 2);
            }
        }
    }, [active]);
    
    const handleClick = (id, offset = 0) => {
        const target = document.getElementById(`category-${id}`);
        const rect = target.getBoundingClientRect();
        const absoluteElementTop = rect.top + window.scrollY;
        const offsetPosition = absoluteElementTop - offset;
    
        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
        });
    }

    const renderCategoriers = (arr) => {
        if (arr.length === 0) return null;
        return arr.map(item => (
            <div ref={item.id === active ? activeRef : null} key={item.id} className={`category-item ${active === item.id ? 'active' : ''}`} onClick={() => handleClick(item.id, 100)}>{item.name}</div>
        ));
    }

    return (
        <div className="row sticky-top mb-2">
            <div ref={categoriesContainerRef} className="col d-flex categories-list">
                {renderCategoriers(categories)}
            </div>
        </div>
    )

}