import { useDispatch, useSelector } from "react-redux";
// import { getCart } from "./cartSlice";
import { useEffect } from "react";
import { CartList } from "./cartList";
import { FixedButton } from "../template/buttons/fixed-button/fixed-button";



export const Cart = () => {

    return <CartList/>
}