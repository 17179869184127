import { NavLink, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeOrderType } from "../../orders/ordersSlice";
import { selectAll as productsSelector } from "../../menu/products/productsSlice";
import { AnimatePresence, motion } from "framer-motion";

import { FixedButton } from "../../template/buttons/fixed-button/fixed-button";

import { ReactComponent as CartIcon } from "../../../assets/images/icons/cart.svg";
import { ReactComponent as MenuIcon } from "../../../assets/images/icons/menu.svg";
import { ReactComponent as HistoryIcon } from "../../../assets/images/icons/history.svg";


import './footer.css';
import { setCurrentTotal } from "../../cart/cartSlice";
import { useEffect } from "react";
import { MINIMUM_ORDER_AMOUNT } from "../../..";




export const Footer = () => {
    const dispatch = useDispatch();
    const hasPayment = useSelector(state => state.header.hasPayment);
    const count = useSelector(state => state.cart.count);
    const cart = useSelector(state => state.cart.cart);
    const total = useSelector(state => state.cart.cartTotal);
    const products = useSelector(productsSelector);
    const location = useLocation();

    useEffect(() => {
        dispatch(setCurrentTotal({ cart, products }));
    }, [cart, products, dispatch]);

    const isMenu = location.pathname === '/';
    const isCart = location.pathname.includes('/shipping') || location.pathname.includes('/checkout');

    if (!hasPayment) {
        return null;
    }

    const footerAnimate = {
        open: { opacity: 1, y: '0vh' },
        closed: { opacity: 0, y: '100vh' }
    };

    return (
        <>
            {isMenu &&
                <AnimatePresence mode="wait">
                    <motion.footer
                        initial="closed"
                        animate={count ? 'open' : 'closed'}
                        exit="closed"
                        variants={footerAnimate}
                        transition={{ duration: 0.5 }}
                    >
                        <FixedButton
                            title={'В кошик'}
                            count={count}
                            link='/cart'
                            cancelRedirect={total <= MINIMUM_ORDER_AMOUNT} // Укажите условие для отображения поповера
                            message={`Щоб зробити замовлення, додайте товарів ще на ${800 - total} грн`}
                            className="cart-button"
                        />
                    </motion.footer>
                </AnimatePresence>
            }
        </>
    );
};
