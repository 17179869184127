import { useField } from "formik"

export const ShippingMethods = ({data, isChecked, ...props}) => {
    const [field, meta] = useField({...props, type: 'radio'});

    if (data === 'undefined' || data === null) return null; 

    const input = Object.entries(data).map(([method, value], index) => (
        method !== 'local' && Number(value.status) !== 0 &&
        <div className="form-check form-check-inline" key={index}>
            <input className="form-check-input " type='radio' {...field} {...props} id={method} value={method} checked={isChecked === method}/>
            <label className="form-check-label fw-bold" htmlFor={method}>{method === 'shipping' ? 'Доставка' : 'Самовивіз'}</label>
        </div>
    ));

    return (
        <div className="mb-2">
            <label className="form-label fs-6 fw-bold">Тип замовлення</label><br/>
            {input}
            {meta.touched && meta.error ? (
                <div className="error-message">{meta.error}</div> 
                ) : null
            }
        </div>
    )
}