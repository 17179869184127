import { useField } from "formik"

export const PayMethods = ({data, isChecked, ...props}) => {
    const [field, meta] = useField({...props, type: 'radio'});

    if (data === 'undefined' || data === null) return null; 
    console.log(data);
    const input = Object.entries(data).map(([method, value], index) => {
        console.log(method);
        let methodName = value.name.split(' ').slice(1).join(' ');
        methodName = methodName.charAt(0).toUpperCase() + methodName.slice(1)
        return (
            Number(value.status) !== 0 && method !== 'local' &&
            <div className="form-check form-check-inline" key={index}>
                <input className="form-check-input " type='radio' {...field} {...props} id={method} value={method} checked={isChecked === method}/>
                <label className="form-check-label fw-bold" htmlFor={method}>{methodName}</label>
            </div>
        )
    });

    return (
        <div className="mb-2">
            <label className="form-label fs-6 fw-bold">Спосіб оплати</label><br/>
            {input}
            {meta.touched && meta.error ? (
                <div className="error-message">{meta.error}</div> 
                ) : null
            }
        </div>
    )
}