

export const PayBtn = ({props, onHandleClick}) => {

    const {payment, orderId, paid} = props;

    const hasOnlinePayment = Object.values(payment).some((method) => method.type === 'online' && Number(method.status) === 1);

    if (!hasOnlinePayment) {
        return null;
    }

    return (
        <tr style={{borderBottom: 'none', borderColor: 'white'}}>
            <td colSpan={5} className='text-center'>
                <div className="accordion accordion-flush" id={orderId}>
                    <div className="accordion-item">
                        {paid && Number(paid) === 2 
                            ?
                                <h2 className="accordion-header">
                                    <button className="btn w-100 collapsed text-center" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOne-${orderId}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                        Оплачено
                                    </button>
                                </h2>
                            :
                            <>
                                <h2 className="accordion-header">
                                    <button className="btn w-100 collapsed text-center" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapseOne-${orderId}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                        Онлайн оплата
                                    </button>
                                </h2>
                                <div id={`flush-collapseOne-${orderId}`} className="accordion-collapse collapse" data-bs-parent={orderId}>
                                    <div className="accordion-body p-0 py-1">
                                        {Object.entries(payment).map(([method, values], index) => {
                                            if (method !== "cash" && method !== "card" && Number(values.status) === 1){

                                                let methodName = values.name.split(' ').slice(1).join(' ');
                                                methodName = methodName.charAt(0).toUpperCase() + methodName.slice(1)

                                                return (
                                                    <button key={index} onClick={() => onHandleClick({method, orderId})} className='btn w-100 mb-1'>{methodName}</button>
                                                )
                                            }
                                        })}
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </td>
        </tr>
    )
}