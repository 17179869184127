
import { parseIframeAttributes } from "../../../helpers/iFrameParser";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Contacts } from "./contacts";

import {ReactComponent as ShopIcon} from "../../../assets/images/icons/shop.svg";
import { ReactComponent as  AboutIcon} from "../../../assets/images/icons/about.svg";



export const AboutUs = () => {
    const location = useLocation();
    const rest = location.state;
    const [iframeAttributes, setIframeAttributes] = useState({});

    const {companyName = '', about = '', map = '', ...constacts} = rest;

    useEffect(() => {
        if (map.trim().length !== 0) {
            setIframeAttributes(parseIframeAttributes(map))
        }
    }, [map]);

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'visible';
            document.body.style.paddingRight = 0;
        };
    }, []);
    return (
        <>
            {companyName.length !== 0 &&
                <div className="row">
                    <div className="col border-bottom mt-3 pb-3">
                        <div className="d-flex align-items-center">
                            <p className="fw-bold m-0 ms-2">Назва:</p>
                            <p className="fw-bold m-0 ms-2">{companyName}</p>
                        </div>
                    </div>
                </div>
            }
            {about.length !== 0 &&
                <div className="row">
                    <div className="col border-bottom mt-3">
                        <div className="d-flex align-items-center">
                            <AboutIcon/>
                            <p className="fw-bold m-0 ms-2">Про компанію:</p>
                        </div>
                        <p className="fs-5 ms-3">{about}</p>
                    </div>
                </div>
            }
            <div className="row border-bottom mt-3 pb-3">
                <div className="col-12 col-sm-6">  
                    {Object.keys(constacts).length !== 0  &&
                        <Contacts rest={{...constacts, }}/>
                    }</div>
                <div className="col-12 col-sm-6">
                    {Object.keys(iframeAttributes).length !== 0  &&
                        <div className="row">
                            <div className="colmt-3">
                                <div className="d-flex align-items-center">
                                <iframe
                                    src={iframeAttributes.src}
                                    width={`100%`}
                                    height={250}
                                    style={{ border: iframeAttributes.style, borderRadius: 10 }}
                                    allowFullScreen={iframeAttributes.allowfullscreen}
                                    loading={iframeAttributes.loading}
                                    referrerPolicy={iframeAttributes.referrerpolicy}
                                    title="Map"
                                ></iframe>
                                    
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
          
           
        </>
    )
}