import { configureStore } from "@reduxjs/toolkit";
import categories from '../components/menu/categories/categoriesSlice';
import products from '../components/menu/products/productsSlice';
import header from '../components/navigation/header/headerSlice';
import cart from '../components/cart/cartSlice';
import customer from "../components/user/customerSlice";
import checkout from "../components/checkout/checkoutSlice";
import orders from '../components/orders/ordersSlice';

const store = configureStore({
    reducer: {categories, products, header, cart, customer, checkout, orders},
    middleware:  getDefaultMiddleware => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== "production"
});

export default store;