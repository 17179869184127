import { useDispatch, useSelector } from "react-redux";
import {decrementQuantity, incrementQuantity } from "./cartSlice";

import {ReactComponent as MinusIcon} from '../../assets/images/icons/minus.svg';
import {ReactComponent as PlusIcon} from '../../assets/images/icons/plus.svg';


export const QuantitySelector = ({id}) => {
    const item = useSelector(state => state.cart.cart).find(item => item.id === id);
    const dispatch = useDispatch();

    const increment = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(incrementQuantity({ id }));
    };

    const decrement = (e) => {
        e.stopPropagation();
        e.stopPropagation();
        dispatch(decrementQuantity({ id }));
    };

    return (
        <div className="quantity-selector">
            <MinusIcon  onClick={decrement}/>
            <div onClick={(e) => {e.preventDefault(); e.stopPropagation()}} className="value">{item ? parseFloat(item.qty) : 1}</div>
            <PlusIcon  onClick={increment}/>
        </div>
    )
}