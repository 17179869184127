import { useWindowSize } from "react-use"
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchProductImage, selectAll as productsSelector, setSingle } from "../products/productsSlice";
import { motion, AnimatePresence} from 'framer-motion';
import { isPast } from 'date-fns';

import { Spinner } from "../../spinner/Spinner";
import { Add } from "../../cart/add";
import { Like } from "../product-card/like";
import { QuantitySelector } from "../../cart/quantity-selector";
import { Modificators } from "./modificators";

import { ReactComponent as Food } from "../../../assets/images/icons/food.svg";
import { ReactComponent as CrossIcon } from "../../../assets/images/icons/cross_second.svg";
import {ReactComponent as ForbidenIcon} from './../../../assets/images/icons/forbiden.svg'


export const SingleProduct = () => {
    const {width} = useWindowSize();
    const productId = useSelector(state => state.products.single);
    const product = useSelector(productsSelector).find(item => item.id === productId);
    const image = useSelector(state => state.products.productsImage[productId]);
    const imageLoadingStatus = useSelector(state => state.products.productImageLoadingStatus[productId]);
    const hasCart = useSelector(state => state.cart.cart).some(item => item.id === product?.id);
    const dispatch = useDispatch();
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        if (imageLoadingStatus !== 'idle') {
            dispatch(fetchProductImage({id: productId}))
        }
    }, []);

    useEffect(() => {
        if (isClosing) {
            setTimeout(() =>dispatch(setSingle(false)), 300); 
        }
    }, [isClosing]);

    const handleClose = () => {
        setIsClosing(true);
    };

    useEffect(() => {
       
        const originalStyle = window.getComputedStyle(document.body).overflow;  
        
        document.body.style.overflow = 'hidden';
       
        return () => document.body.style.overflow = originalStyle;
      }, []);

    const modalBg = {
        open: { opacity: 1},
        closed: { opacity: 0},
    };
    const modalContent = {
        open: { opacity: 1, y: width < 576 ? "20vh" : "0vh" },
        closed: { opacity: 1, y: "100vh" },
    };

    const btns = hasCart
    ? <QuantitySelector id={product.id} />
    : <Add rest={product} />;

    let cardActions;

    if (product.stopListStart === '0' && product.stopListEnd === '0') {
        cardActions = btns;
    } else if (product.stopListStart !== '0' && product.stopListEnd !== '0' && !isPast(product.stopListEnd)) {
        cardActions = (
            <div style={{ width: 83, height: 40 }}>
                <ForbidenIcon height={40} />
            </div>
        );
    } else if (product.stopListStart !== '0' && product.stopListEnd === '0') {
        cardActions = (
            <div style={{ width: 83, height: 40 }}>
                <ForbidenIcon height={40} />
            </div>
        );
    } else {
        cardActions = btns;
    }

    return (
        <AnimatePresence mode="wait">
            <motion.div
            initial="closed"
            animate={isClosing ? "closed" : "open"}
            exit="closed"
            variants={modalBg}
            transition={{ duration: 0.3 }}
            className="modal fade show " 
            onClick={handleClose} 
            style={{display: 'block'}}>
                <motion.div 
                    initial='closed'
                    animate={isClosing ? "closed" : "open"}
                    exit="closed"
                    variants={modalContent}
                    transition={{ duration: 0.3 }}
                    className={`modal-dialog modal-dialog-scrollable ${width < 768 && 'modal-fullscreen'}` }
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="modal-content position-relative" style={{minHeight: 500}}>
                        <>
                            <div className="modal-header p-0 position-relative">
                                <CrossIcon className='position-absolute cross-icon top-0 end-0 mt-2 me-2' onClick={handleClose}/>
                                { image ?
                                    <img className="w-100 " src={image} alt="" /> :
                                    <Food style={{height: 300, width:300, margin: '0 auto'}}/>
                                }
                            </div>
                            <div className="modal-body">
                                <h1 className="modal-title fs-5" >{product?.name}</h1>
                                <p className="product-description">{product?.description}</p>
                                {product?.modificators && product?.modificators.lenght !== 0 &&
                                    <Modificators productId={productId} modificators={product?.modificators} hasCart={hasCart}/>
                                }
                            </div>
                            <div className="modal-footer d-flex justify-content-between " style={{height: 80}}>
                                <div className="h3">{product?.price}</div>
                                <Like rest={{id: product?.id, likes: product?.likes}}/>
                                {cardActions}
                            </div>
                         </>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    )
}