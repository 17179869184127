import { Component } from "react";

import { ErrorPage } from "../error-page";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            hasError: false,
            errorMessage: "Щось пішло не так...",
        };
    }
    

    componentDidCatch(error, info) {
        this.setState({
            hasError: true,
            errorMessage: error?.details?.message, 
        });
        
    }
    render() {
        if (this.state.hasError) {
            return <ErrorPage message={this.state.errorMessage}/>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;