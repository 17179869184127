import { Link } from "react-router-dom";

import {ReactComponent as CrossIcon} from "../../assets/images/icons/cross.svg";

export const Fail = () => {
    return (
        <div className="container d-flex align-items-center justify-content-center text-center mt-5">
            <div className="row align-items-center justify-content-center">
                <div className="col-auto">
                    <CrossIcon/>
                </div>
                <div className="w-100  mt-3"></div>
                <div className="col-auto">
                    <p className="h5">Сталася непередбачена помилка!</p>
                    <p className="h5">Спробуйте ще раз або повторіть спробу пізніше</p>
                </div>
                <div className="w-100 mt-3"></div>
                <div className="col-auto">
                    <Link
                        to={'/'}
                        className="btn"
                    >
                        На головну
                    </Link>
                </div>
            </div>
        </div>
    )
}