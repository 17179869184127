import { format } from 'date-fns';
import { uk } from 'date-fns/locale';

import {ReactComponent as ScheduleIcon} from "../../../assets/images/icons/schedule.svg";

export const Schedule = ({rest}) => {

    const  today = format(new Date(), 'eeee', {locale: uk});


    const result = rest.map((item, index) => {
        const day = Object.keys(item)[0];
        const hours = item[day];
        return (
          <tr key={index}>
            <td className={today.toLowerCase() === day.toLowerCase() ? 'fw-bold' : ''}>{day.charAt(0).toUpperCase() + day.slice(1)}</td>
            <td className={today.toLowerCase() === day.toLowerCase() ? 'fw-bold' : ''}>{hours.start} - {hours.end}</td>
          </tr>
        );
    })

    return (
        <div className="border-bottom border-secondary-subtle">
            <div className="d-flex align-items-center">
                <ScheduleIcon/>
                <p className="h4 m-0 ms-2">Графік роботи</p>
            </div>
            <table className="ms-2 mt-2 mb-3">
                <tbody> 
                    {result}
                </tbody>
            </table>
        </div>
    )
}