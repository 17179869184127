import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../../hook/http.hook";


const headerAdapter = createEntityAdapter();

const initialState = headerAdapter.getInitialState({
    headerLoadingStatus: 'idle',
    aboutActive: false,
    settings: [],
    about: [],
    schedule: [],
    hasPayment: false,
    hasDelivery: false,
    hasLocal: false,
});

export const fetchSettings = createAsyncThunk(
    'header/fetchSettings',
    async () => {
        const { request } = useHttp();

        const response = await request('settings');

        return response;
    }
);

function schedule(str) {
    if (str) {

        const schedule = JSON.parse(str);
        return [
            {'понеділок': {
                start: schedule.mn_from,
                end:  schedule.mn_to,
            }},
            {'вівторок': {
               start: schedule.tu_from,
                end:  schedule.tu_to,
            }},
            {'середа': {
                start: schedule.ve_from,
                end:  schedule.ve_to,
            }},
            {'четвер': {
                start: schedule.th_from,
                end:  schedule.th_to,
            }},
            {"п’ятниця": {
                start: schedule.fr_from,
                end:  schedule.fr_to,
            }},
            {"субота": {
                start:  schedule.sa_from,
                end:   schedule.sa_to,
            }},
            {"неділя": {
                start:  schedule.su_from,
                end:   schedule.su_to,
            }},
        ];
    }

    return [];
}

function hasPayment(obj) {
    if (obj && Object.keys(obj) !== 0) {
        return Object.values(obj).some(item => item.status !== '0');
    }

    return false;
}

function hasDelivery(obj) {
    if (obj && Object.keys(obj) !== 0 && (obj?.shipping?.status !== '0' || obj?.pickup?.status !== '0')) {
        return true;
    }

    return false
}

function hasLocal(obj) {
    if (obj && Object.keys(obj) !== 0 && obj?.local?.status !== '0') {
        return true;
    }

    return false
}

const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        showModal(state, action) {
            state.aboutActive = !action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchSettings.pending, state => { state.headerLoadingStatus = 'loading' })
        .addCase(fetchSettings.fulfilled, (state, action) => {
            state.headerLoadingStatus = 'idle';
            state.settings = action.payload.result?.settings;
            state.schedule = schedule(action.payload.result?.settings?.schedule);
            state.about = action.payload.result?.about;
            state.hasPayment = hasPayment(action.payload.result?.settings?.payment);
            state.hasDelivery = hasDelivery(action.payload.result?.settings?.orderTypes);
            state.hasLocal = hasLocal(action.payload.result?.settings?.orderTypes);
        })
        .addCase(fetchSettings.rejected, state => { state.headerLoadingStatus = 'error' })
        .addDefaultCase( () => {})
    }
});

export const { selectAll } = headerAdapter.getSelectors(state => state.header);

const { reducer, actions } = headerSlice;

export default reducer;

export const { showModal } = actions;