export const parseIframeAttributes = (iframeHtml) => {
    const attributes = {};
    const parser = new DOMParser();
    const doc = parser.parseFromString(iframeHtml, 'text/html');
    const iframe = doc.querySelector('iframe');
    
    if (iframe) {
        attributes.src = iframe.getAttribute('src');
        attributes.width = iframe.getAttribute('width') || '100%';
        attributes.height = iframe.getAttribute('height') || '500px';
        attributes.style = iframe.getAttribute('style') || 'border: 0';
        attributes.allowfullscreen = iframe.hasAttribute('allowfullscreen');
        attributes.loading = iframe.getAttribute('loading') || 'lazy';
        attributes.referrerpolicy = iframe.getAttribute('referrerpolicy') || 'no-referrer-when-downgrade';
    }
    
    return attributes;
};