import { useField } from "formik"


export const Checkbox = ({label, ...props}) => {
    const [field, meta] = useField({...props, type: 'checkbox'})
    return (
        <div className="form-check">
            <input className="form-check-input " type="checkbox" {...field} {...props}/>
            <label className="form-check-label" htmlFor={props.name}>
                {label}
            </label>
            {meta.touched && meta.error ? (
                <div className="error-message">{meta.error}</div> 
                ) : null
            }
        </div>
    )
}