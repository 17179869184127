

import {ReactComponent as FoodIcon} from "../../../assets/images/icons/food.svg";

export const CardDesktop = ({children, rest, img}) => {;
    return (
        <div className="card">
            <div className="card-img-top position-relative">
                { img ?
                    <img src={img} alt={rest.name} /> :
                    <FoodIcon className='default-img'/>
                }
                {children.length > 1 &&
                    <div className="wrap-like position-absolute top-0 end-0 m-2 px-1">
                        {children[0]}
                    </div>
                }
            </div>
            <div className="card-body">
                <span className="card-title h5">{rest.name}</span>
                {rest.description &&
                    <p className="card-text">{rest.description}</p>
                }
            </div>
            <div className="card-footer d-flex justify-content-between align-items-center" style={{height: 60}}>
                <div className='h5'>{rest.price}</div>
                {children.length > 1&&
                   <div>{children[1]}</div>
                }
            </div>
        </div>
    )
}