import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLike, setTmpId, likes as getLikes } from "../../user/customerSlice";
import { updateLikes } from "../products/productsSlice";


export const Like = ({rest}) => {
    const dispatch = useDispatch();
    const like = useSelector(state => state.customer.customer.likes).some(item => item.id === rest.id);
    const customer = useSelector(state => state.customer.customer.phone);
    const tmpId = useSelector(state => state.customer.tmpId);
    const [customerId, setId] = useState(null);
    const [hasLike, setLike] = useState(like);

    useEffect(() => {
        if (customer) {
            setId(customer);
        } else {
            setId(tmpId);
        }
    }, [customer, tmpId]);

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (customerId) {
            dispatch(updateLike({ user: customerId, id: rest.id }));
            setLike(prevHasLike => !prevHasLike)
            dispatch(updateLikes({id: rest.id, status : !hasLike}));
        }
    };


    return (
        <div className="like-wrap my-2 me-2" >
            <svg onClick={handleClick} viewBox="0 0 24 24" fill="red" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                <path fillRule={!hasLike && 'evenodd'}  d="M12.0122 5.57169L10.9252 4.48469C8.77734 2.33681 5.29493 2.33681 3.14705 4.48469C0.999162 6.63258 0.999162 10.115 3.14705 12.2629L11.9859 21.1017L11.9877 21.0999L12.014 21.1262L20.8528 12.2874C23.0007 10.1395 23.0007 6.65711 20.8528 4.50923C18.705 2.36134 15.2226 2.36134 13.0747 4.50923L12.0122 5.57169ZM11.9877 18.2715L16.9239 13.3352L18.3747 11.9342L18.3762 11.9356L19.4386 10.8732C20.8055 9.50635 20.8055 7.29028 19.4386 5.92344C18.0718 4.55661 15.8557 4.55661 14.4889 5.92344L12.0133 8.39904L12.006 8.3918L12.005 8.39287L9.51101 5.89891C8.14417 4.53207 5.92809 4.53207 4.56126 5.89891C3.19442 7.26574 3.19442 9.48182 4.56126 10.8487L7.10068 13.3881L7.10248 13.3863L11.9877 18.2715Z" fill="currentColor"></path>
            </svg>
                <span className='ms-1'>
                    {Number(rest.likes) !== 0 &&
                        Number(rest.likes)
                    }
                </span>
        </div>
    )
}