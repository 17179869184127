import { useNavigate } from "react-router-dom";

import "./not-found.css";

export const NotFound = () => {

    const navigate = useNavigate();

    const handleClick = () => {
        return navigate('/');
    }

    return (
        <div className="container vh-100 d-flex align-items-center justify-content-center text-center not-found">
            <div className="row justify-content-center">
                <div className="col-auto">
                    <p className="firts-text">404</p>
                </div>
                <div className="w-100"></div>
                <div className="col-auto">
                    <p className="second-text">Нічого не знайдено...</p>
                </div>
                <div className="w-100"></div>
                <div className="col-auto">
                <div className="col-auto">
                    <div onClick={handleClick} className="btn btn-dark">
                        На головну
                    </div>
                </div>
                </div>
                <div className="w-100"></div>
            </div>
        </div>
    )
}