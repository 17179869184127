import { format, isFuture, isPast, parse } from "date-fns";
import { uk } from 'date-fns/locale';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as CrossIcon } from "../../assets/images/icons/cross_second.svg";

import './shipping.css';

export const IsClose = ({children}) => {
    const schedule = useSelector(state => state.header.schedule);
    const navigate = useNavigate();
    const [warning, setWarning] = useState({
        status: false,
        message: ''
    })

    const handleClick = () => {
        if (schedule.length !== 0) {
            const now = new Date();

            const day = format(now, 'eeee', {locale: uk});

            const workHours = schedule.find(item => item.hasOwnProperty(day));

            const openTime = parse(workHours[day].start, "HH:mm", new Date());
            const closeTime =  parse(workHours[day].end, 'HH:mm', new Date());

            const formattedOpenTime = format(openTime, 'HH:mm');
            if (isFuture(openTime)) {
                setWarning({
                    status: true, 
                    message: 'Ми відкриємось о ' + formattedOpenTime + '.'
                })
            } else if (isPast(closeTime)) {
                setWarning({
                    status: true, 
                    message: 'Ми на разі заклад закритй, будь-ласк зробіть замовлення в робочий час '
                })
            } else {
                return navigate(`/shipping`)
            }
        } else {
          return navigate(`/shipping`)
        }
    }

    return (
        <>
            <div onClick={() => handleClick()}>
                {children}
            </div>
            {warning.status &&
                <WarningMessage rest={warning} onSetWarning={setWarning}/>
            }
        </>
    )
}

const WarningMessage = ({ rest, onSetWarning }) => {
    const [isClose, setClose] = useState(true);

    useEffect(() => {
      if (!isClose) {
        setTimeout(() => {
            onSetWarning({status: false, message: ''});
        }, 300);
      }
     
    }, [isClose]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setClose(false);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    const handleClick  = () => {
        setClose(false)
    }
  
    const variants = {
      open: { opacity: 1, x: 0 },
      closed: { opacity: 0, x: "100%" },
    };
  
  
    return (
      <motion.div
        className="toast-container p-3 top-0 end-0 position-fixed"
        id="toastPlacement"
        initial="closed"
        animate={isClose ? "open" : "closed"}
        variants={variants}
        transition={{ duration: 0.5 }}
      >
        <div className="toast fade show">
          <div className="toast-header">
            <strong className="me-auto ">Увага!</strong>
            <CrossIcon onClick={() => handleClick()} />
          </div>
          <div className="toast-body fw-bold">
            {rest.message}
          </div>
        </div>
      </motion.div>
    );
};