import { useWindowSize } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductImage, setSingle } from '../products/productsSlice';
import { isPast } from 'date-fns';
import { useEffect, useRef } from 'react';
import { motion, useInView } from 'framer-motion';

import { CardMobile } from './card-mobile';
import { CardDesktop } from './card-desktop';
import { QuantitySelector } from '../../cart/quantity-selector';
import { Add } from '../../cart/add';
import { Like } from './like';

import {ReactComponent as ForbidenIcon} from './../../../assets/images/icons/forbiden.svg'

import './products-card.css';

export const ProductCard = ({rest, index}) => {
    const image = useSelector(state => state.products.productsImage[rest?.id]);
    const imageLoadingStatus = useSelector(state => state.products.productImageLoadingStatus[rest?.id]);
    const { width } = useWindowSize();
    const cart = useSelector(state => state.cart.cart);
    const dispatch = useDispatch();
    const ref = useRef(null);
    const inView = useInView(ref, { once: true });console.log(isPast(rest.stopListEnd));

    useEffect(() => {
        if (inView && imageLoadingStatus !== 'idle') {
            dispatch(fetchProductImage({id: rest?.id}));
        }
    }, [inView, imageLoadingStatus, dispatch, rest?.id]);

    const btns = cart.some(item => item.id === rest.id) 
    ? <QuantitySelector id={rest.id} />
    : <Add rest={rest} />;

    let cardActions;

    if (rest.stopListStart === '0' && rest.stopListEnd === '0') {
        cardActions = btns;
    } else if (rest.stopListStart !== '0' && rest.stopListEnd !== '0' && !isPast(rest.stopListEnd)) {
        cardActions = (
                <button className="add"  disabled={true}>
                    Додати
                </button>
        );
    } else if (rest.stopListStart !== '0' && rest.stopListEnd === '0') {
        cardActions = (
                <button className="add"  disabled={true}>
                    Додати
                </button>
        );
    } else {
        cardActions = btns;
    }

    return (
        <motion.div
            ref={ref}
            initial={index < 11 ? { opacity: 0, x: -50 } : {opacity: 1}}
            animate={index < 11 ? { opacity: 1, x: 0 } : {opacity: 1}} 
            transition={index < 11 ? { duration: 0.3 } : {duration: 0}}
            onClick={() => dispatch(setSingle(rest.id))}
            className="col-12 mb-md-4 col-md-6 col-lg-3"
        >
            {width < 768 ?
                <CardMobile img={image} rest={rest}>
                    <Like rest={{id: rest.id, likes: rest.likes}}/>
                    {cardActions}
                </CardMobile>
            :
                <CardDesktop img={image} rest={rest}>
                    <Like rest={{id: rest.id, likes: rest.likes}}/>
                    {cardActions}
                </CardDesktop>
            }
        </motion.div>
    )
}
