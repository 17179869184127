import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setModificator } from "../../cart/cartSlice";
import { isPast } from "date-fns";

export const Modificators = ({productId, modificators, hasCart}) => {
    const dispatch = useDispatch();
    const hasModificators = useSelector(state => state.cart.cart).filter(item => item.id === productId)[0]?.modificators || [];
    const handleClick = (productId, modificator, type = null) => {
        dispatch(setModificator({productId, modificator, type}))
    }

    const isDisabledHandle = (item) => {
        if (item.stopListStart === '0' && item.stopListEnd === '0') {
            return false
        } else if (item.stopListStart !== '0' && isPast(item.stopListEnd)) {
            return true
        } else if (item.stopListStart !== '0' && item.stopListEnd === '0') {
            return true
        } else {
            return false
        }
    }

    const content = Object.values(modificators).map((value, index) => {
        return (
            <React.Fragment key={index}>
                <p className="h2 fs-6">{value.name}</p>
                <ul className="list-group">
                    {value.modificators.map(item => {
                        console.log(item);
                        const isSelected = hasModificators.some(mod => mod.id === item.id);
                        const isDisabled = isDisabledHandle(item);console.log('mod',isDisabled)
                        return (
                            <li key={item.id} className="list-group-item">
                                <label className="form-check-label d-flex align-items-center" htmlFor={`item-${item.id}`}>
                                    <input
                                        className="form-check-input me-1"
                                        type={value.type === 'checkbox' ? 'checkbox' : 'radio'}
                                        onChange={() => handleClick(productId, item, value.type === 'checkbox' ? 'checkbox' : 'radio')}
                                        id={`item-${item.id}`}
                                        disabled={isDisabled || !hasCart}
                                        checked={isSelected}
                                    />
                                    <span>{item.name}</span>
                                    {item.price && item.price !== "0.00" &&
                                        <span className="ms-auto">+{item.price}</span>
                                    }
                                </label>
                            </li>
                        );
                    })}
                </ul>
            </React.Fragment>
        )
    });
    return content
}
