import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom";

import { Layout } from "../components/layout/layout";
import { Menu } from "../components/menu/menu";
import { Cart } from "../components/cart/cart";
import { Shipping } from "../components/shipping/shipping";
import { Success } from "../components/notifications/success";
import { Fail } from "../components/notifications/fail";
import { Checkout } from "../components/checkout/checkout";
import { Orders } from "../components/orders/order";
import { Signin } from "../components/user/signin";
import { RequireAuth } from "../hoc/user/RequireAuth";
import { Search } from "../components/search/search";
import { PreLoader } from "../hoc/PreLoader/PreLoader";
import { RouteError } from "../components/errors/route-error/route-error";
import ErrorBoundary from "../components/errors/error-boundary/error-boundary";
import { NotFound } from "../components/errors/404/not-found";
import { AboutUs } from "../components/navigation/header/aboutUs";
import { TermsOfUse } from "../components/navigation/header/termsOfUse";
import { CookiePolicy } from "../components/navigation/header/cookiePolicy";

const router = createBrowserRouter(createRoutesFromElements(
    <Route path="/" element={
        <PreLoader>
            <Layout/>
        </PreLoader> 
    }>
         <Route index element={<Menu/>} errorElement={<RouteError/>}/>
         <Route path="/cart" element={<Cart/>}/>
         <Route path="/shipping" element={<Shipping/>}/>
         <Route path="/checkout" element={<Checkout/>}/>
         <Route path="/orders/*" element={
            <RequireAuth>
                <Orders/>
            </RequireAuth>
         }/>
         <Route path="/signin"element={<Signin/>}/>
         <Route path="/search" element={<Search/>}/>
         <Route path="/success" element={<Success/>}/>
         <Route path="/fail" element={<Fail/>}/>
         <Route path="/aboutUs" element={<AboutUs />}/>
         <Route path="/terms-of-use" element={<TermsOfUse/>}/>
         <Route path="/cookie-policy" element={<CookiePolicy/>}/>
         <Route path="/*" element={<NotFound/>}/>
    </Route>
), {basename: '/'});

function AppRoutes() {
   return (
    <ErrorBoundary>
        <RouterProvider router={router}/>
    </ErrorBoundary>
   )
}

export default AppRoutes;