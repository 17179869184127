import { useEffect } from "react";


export const CookiePolicy = () => {

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'visible';
            document.body.style.paddingRight = 0;
        };
    }, []);

    return (
        <div className="container mt-3 mb-5">
            <h1>Як ми використовуємо Cookie</h1>
            <p>Cookie — це невеликий текстовий файл, який сайт зберігає на вашому комп'ютері або мобільному пристрої, коли ви відвідуєте сайт. Браузери підтримують файли cookie та аналогічні технології (як-от локальне зберігання та пікселі), щоб сайти, подібні до нашого, могли запам'ятовувати інформацію про ваше відвідування та використовувати її для поліпшення вашого досвіду і створення сукупної анонімної статистики про використання сайту. У цій умові ми використовуємо термін 'кукі' для позначення як кукі, так і аналогічних технологій.</p>

            <p>Cookies можуть бути встановлені сайтом, який ви відвідуєте (так звані 'cookies першої сторони'), або іншою стороною, наприклад, тими, хто надає аналітичні або рекламні послуги або інтерактивний контент на сайті ('cookies третьої сторони').</p>

            <h2>Наші основні файли cookie включають:</h2>
            <ul>
                <li><strong>Необхідні файли Cookie:</strong> Ці файли cookie необхідні для функціонування сайту і не можуть бути відключені в наших системах. Ви можете налаштувати свій браузер на блокування або попередження про ці файли cookie, але це призведе до того, що деякі частини сайту не працюватимуть. До них належать:</li>
            </ul>
            <table border="1">
                <thead>
                    <tr>
                        <th>ІМ'Я</th>
                        <th>ОПИС</th>
                        <th>EXPIRES</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>lang</td>
                        <td>Цей файл cookie запам'ятовує яка у користувача поточна мова</td>
                        <td>30 днів</td>
                    </tr>
                    <tr>
                        <td>mguid</td>
                        <td>Цей файл cookie призначений для визначення сесії клієнта</td>
                        <td>20 днів</td>
                    </tr>
                    <tr>
                        <td>language</td>
                        <td>Цей файл cookie запам'ятовує яка у користувача поточна мова</td>
                        <td>1 рік</td>
                    </tr>
                    <tr>
                        <td>autoLang</td>
                        <td>Цей файл cookie призначений для того, що б визначати, чи є поточна мова оригіналом</td>
                        <td>1 рік</td>
                    </tr>
                    <tr>
                        <td>cookieSettings</td>
                        <td>Цей файл cookie призначений для того, щоб ми запам'ятовували вибір користувача які куки залишати</td>
                        <td>1 рік</td>
                    </tr>
                </tbody>
            </table>

            <h2>Google Maps</h2>
            <p>Google Maps API - це картографічна служба, яку надає компанія Google Inc., 1600 Amphitheater Parkway, Mountain View, CA 94043, США. Нам потрібно використовувати Google Maps API, щоб ви могли вказати місце доставки вашого замовлення на карті. При використанні Google Maps інформація про використання вами цього веб-сайту (включаючи ваш IP-адресу) може передаватися на сервер Google в США та зберігатися там. Нижче подані посилання надають інформацію про захист даних та Умови використання Google Maps.</p>

            <h2>Як я можу контролювати файли cookie і як використовуються мої дані?</h2>
            <p>Існує кілька способів, за допомогою яких ви можете контролювати, яка інформація збирається файлами cookie на нашому сайті і як ця інформація використовується.</p>
            
            <h3>Налаштування вашого браузера</h3>
            <p>У вашому браузері є елементи керування, які дають змогу вам керувати використанням файлів cookie на відвідуваних вами сайтах. Більшість браузерів мають функції, що дають змогу переглядати та видаляти файли cookie, що зберігаються на вашому пристрої, а також блокувати файли cookie на всіх або обраних сайтах. Для отримання додаткової інформації тут наведено посилання на зовнішні довідкові матеріали для деяких популярних браузерів:</p>
            <ul>
                <li><a href="https://support.mozilla.org/uk/kb/kak-otklyuchit-fajly-cookies" target="_blank">Mozilla Firefox</a></li>
                <li><a href="https://support.apple.com/uk-ua/HT201265" target="_blank">Apple Safari</a></li>
                <li><a href="https://support.google.com/chrome/answer/95647" target="_blank">Google Chrome</a></li>
                <li><a href="https://support.microsoft.com/uk-ua/help/4468242/microsoft-edge-browsing-data-and-privacy" target="_blank">Microsoft Edge</a></li>
            </ul>

            <h3>Налаштування вашого мобільного пристрою</h3>
            <p>Ваш мобільний пристрій також може мати налаштування браузера, що дають змогу контролювати використання файлів cookie, особливо якщо пристрій підтримує встановлення додатків, як, наприклад, пристрої iOS і Android. Пристрої OS і Android також включають додаткові налаштування пристрою, які контролюють, чи можуть рекламні партнери використовувати інформацію про вашу активність у додатку в рекламних цілях. На пристроях iOS ви можете знайти налаштування під назвою 'Обмежити відстеження реклами'. На пристроях Android ви можете знайти налаштування під назвою 'Відмовитися від персоналізації реклами'.</p>

            <h3>Керування файлами cookie</h3>
            <p>Також можна повністю заборонити прийняття браузером файлів cookie, змінивши налаштування файлів cookie в браузері. Зазвичай ці налаштування можна знайти в меню 'Параметри' або 'Налаштування' вашого браузера. Зверніть увагу, що видалення наших файлів cookie або вимкнення майбутніх файлів cookie або технологій відстеження може позбавити вас доступу до певних розділів або функцій наших Послуг або іншим чином негативно позначитися на вашому досвіді.</p>

            <p>Ми можемо оновлювати цю Політику використання файлів cookie. Ми рекомендуємо вам періодично переглядати цю сторінку для отримання останньої інформації про файли cookie.</p>

            <h3>Ваш поточний вибір</h3>
            <p>Ви можете змінити налаштування файлів cookie, натиснувши на цю кнопку:</p>
            <button>Змінити налаштування файлів cookie</button>
        </div>
    )
}