import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts } from "../../components/menu/products/productsSlice";
import { fetchSettings } from "../../components/navigation/header/headerSlice";
import { fetchCategories } from "../../components/menu/categories/categoriesSlice";

import { ReactComponent as CocktailIcon }  from '../../assets/images/icons/cocktail.svg';
import { LoadingDots } from "../../components/template/loader/loader";
import { fetchOrders } from "../../components/orders/ordersSlice";



export const PreLoader = ({children}) => {
    const dispatch = useDispatch();
    const productsLoadingStatus = useSelector(state => state.products.productsLoadingStatus);
    const headerLoadingStatus = useSelector(state => state.header.headerLoadingStatus);
    const categoriesLoadingStatus = useSelector(state => state.categories.categoriesLoadingStatus);
    const ordersLoadingStatus = useSelector(state => state.orders.ordersLoadingStatus);
    const ordersPreload = useSelector(state => state.orders.ordersPreload);
    const customer = useSelector(state => state.customer?.customer?.phone) || null;

    
    useEffect(() => {
        dispatch(fetchProducts());
        dispatch(fetchSettings());
        dispatch(fetchCategories());
        if (customer && !ordersPreload) {
            dispatch(fetchOrders({user: customer, query: 'actual'}))
        }
    }, []);

    console.log(productsLoadingStatus, headerLoadingStatus, categoriesLoadingStatus, ordersLoadingStatus);

    if (
        productsLoadingStatus === 'idle' &&
        headerLoadingStatus === 'idle' &&
        categoriesLoadingStatus === 'idle'
    ) {
        return children;
    }


    const render = (
        <div className="container-fluid text-center d-flex justify-content-center align-items-center" style={{height: "100vh"}}>
            <div className="row align-items-center">
                <div className="col-12 align-self-center mb-5"><CocktailIcon/></div>
                <div className="col-12 align-self-center fw-bold fs-5"><LoadingDots/></div>
            </div>
        </div>
    )
    
    return render;
}
