
import { useDispatch, useSelector } from "react-redux";
import { QuantitySelector } from "./quantity-selector";
import {setCurrentTotal} from './cartSlice';
import { selectAll as productsSelector } from "../menu/products/productsSlice";
import { useEffect} from "react";


import { FixedButton } from "../template/buttons/fixed-button/fixed-button";
import { IsClose } from "../../hoc/shipping/is-close";
import { useNavigate } from "react-router-dom";
import { MINIMUM_ORDER_AMOUNT } from "../..";


export const CartList = () => {
    const cart = useSelector(state => state.cart.cart);
    const total = useSelector(state => state.cart.cartTotal);
    const products = useSelector(productsSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setCurrentTotal({ cart, products }));
    }, [cart, products, dispatch]);

    useEffect(() => {
        if (total < MINIMUM_ORDER_AMOUNT) {
            return navigate('/')
        }
    }, [total])

    if (cart.length === 0) {
        return <p className="h2 text-center mt-2">Тут пусто</p>
    }

    const result = cart.map(cartItem => {
        const product = products.find(product => product.id === cartItem.id);
        const modificators = cartItem?.modificators 
            ? cartItem.modificators.reduce((acc, arr) => acc + parseFloat(arr.price), 0).toFixed(2) 
            : 0;
        if (!product) return null;
        
        return (
            <tr key={product.id}>
                <td>
                    {product.name}
                    {cartItem?.modificators &&
                        <ul class="list-group list-group-flush">
                        {cartItem.modificators.map((item, index) => (
                            <li class="list-group-item" key={index}>-{item.name}</li>
                        ))}
                    </ul>
                    }
                </td>
                <td><QuantitySelector id={product.id} qty={cartItem.qty}/></td>
                <td className="text-end">{`${product.price} ${modificators > 0 ? `(+${modificators})` : ''}`}</td>
                <td className="text-end">{((Number(modificators) + Number(product.price)) * cartItem.qty).toFixed(2)}</td>
            </tr>
        );
    }).filter(Boolean);

    return (
        <>
            <table className="table mb-5">
                <thead>
                    <tr>
                        <th scope="col" className="">Товар</th>
                        <th scope="col" className="">Кількість</th>
                        <th scope="col" className=" text-end">Ціна</th>
                        <th scope="col" className=" text-end">Cума</th>
                    </tr>
                </thead>
                <tbody>
                    {result}
                </tbody>
                <tfoot>
                    <tr>
                        <th scope="col" colSpan={3} className="text-end">До сплати</th>
                        <th scope="col" colSpan={2} className="text-end">{total.toFixed(2)}</th>
                    </tr>
                </tfoot>
            </table>
            <IsClose>
                <FixedButton title={'Оформити'}/>
            </IsClose>
        </>
    )
}