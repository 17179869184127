import { isRouteErrorResponse, useRouteError } from "react-router-dom"

import { ErrorPage } from "../error-page";

export const RouteError = () => {
    const error = useRouteError();

    if (isRouteErrorResponse(error)) {
        const message = 'Щось пішло не так...1';
        return <ErrorPage message={message}/>;
    }
    
    let otherError = new Error('Щось пішло не так...');
    otherError.details = {
        message: 'Щось пішло не так...',
        error: 'unknown_error',
        type: 'unknown',
    };
    throw otherError;
}