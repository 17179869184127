import { ReactComponent as LocationIcon } from "../../../assets/images/icons/location.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/images/icons/phone.svg";
import { ReactComponent as EmailIcon } from "../../../assets/images/icons/email.svg";
import { ReactComponent as TelegramIcon } from "../../../assets/images/icons/telegram.svg";
import { ReactComponent as ContactsIcon} from "../../../assets/images/icons/contacts.svg";
import { ReactComponent as FBIcon } from "../../../assets/images/icons/facebook.svg";
import { ReactComponent as InstIcon } from "../../../assets/images/icons/instagram.svg";
import { ReactComponent as TikTokIcon } from "../../../assets/images/icons/tiktok.svg";

export const Contacts = ({ rest }) => {
    return (
        <div className="border-bottom border-secondary-subtle mt-3">
            <div className="d-flex align-items-center">
                <ContactsIcon />
                <p className="h4 m-0 ms-2">Контакти</p>
            </div>
            <ul className="ms-1 ps-2 ">
                {rest.address &&
                    <li className="list-group-item mt-2">
                        <a 
                            className='text-decoration-none fw-bold' 
                            href={`https://www.google.com/maps/place/${rest.address}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        > 
                            <LocationIcon className={`me-3`} />
                            {rest.address}
                        </a>
                    </li>
                }
                {rest.phone &&
                    <li className="list-group-item mt-2 ">
                        <a 
                            className="text-decoration-none fw-bold" 
                            href={`tel:${rest.phone}`}
                        >
                            <PhoneIcon className={`me-2`} />
                            {rest.phone}
                        </a>
                    </li>
                }
                {rest.email &&
                    <li className="list-group-item mt-2">
                        <a 
                            className="text-decoration-none fw-bold" 
                            href={`mailto:${rest.email}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <EmailIcon className={`me-2`} />
                            {rest.email}
                        </a>
                    </li>
                }
                {rest.telegram &&
                    <li className="list-group-item mt-2">
                        <a 
                            className="text-decoration-none fw-bold" 
                            href={`https://t.me/${rest.telegram}`} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <TelegramIcon className={`me-2`} />
                            <span>Telegram</span>
                        </a>
                    </li>
                }
            </ul>
            <ul className="ms-1 ps-2 mt-2 pb-2 d-flex">
                {rest.facebook &&
                    <li className="list-group-item"> 
                        <a 
                            className="text-decoration-none me-2" 
                            href={rest.facebook} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <FBIcon />
                        </a>
                    </li>
                }
                {rest.instagram &&
                    <li className="list-group-item"> 
                        <a 
                            className="text-decoration-none me-2" 
                            href={rest.instagram} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <InstIcon />
                        </a>
                    </li>
                }
                {rest.tiktok &&
                    <li className="list-group-item"> 
                        <a 
                            className="text-decoration-none me-2" 
                            href={rest.tiktok} 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                            <TikTokIcon />
                        </a>
                    </li>
                }
            </ul>
        </div>
    );
};
