import { useEffect, useState } from "react";
import { useSelector } from "react-redux"

function hexToRgb(hex) {
    if (hex === 'undefined') {
        return null;
    }

    let r = 0, g = 0, b = 0;

    if (hex.length == 4) {
      r = "0x" + hex[1] + hex[1];
      g = "0x" + hex[2] + hex[2];
      b = "0x" + hex[3] + hex[3];

    } else if (hex.length == 7) {
      r = "0x" + hex[1] + hex[2];
      g = "0x" + hex[3] + hex[4];
      b = "0x" + hex[5] + hex[6];
    }
    
    return ""+ +r + "," + +g + "," + +b;
}

export const Style = () => {
    const styles = useSelector(state => state.header.settings.styles);

    useEffect(()=>{
        if (styles?.textColor) {
            setTextColor(hexToRgb(styles.textColor));
        }
        if (styles?.backgroundColor) {
            setBackgroundColor(hexToRgb(styles.backgroundColor));
        }
        if (styles?.headerColor) {
            setHeaderColor(hexToRgb(styles.headerColor));
        }
    }, [styles])

    const [textColor, setTextColor] = useState(hexToRgb(styles?.textColor ||'#000000'));
    const [backgroundColor, setBackgroundColor] = useState(hexToRgb(styles?.backgroundColor || '#ffffff'));
    const [headerColor, setHeaderColor] = useState(hexToRgb(styles?.headerColor || '#ffffff'));

    return (
        <style>
            {`
                :root {
                    --textColor: ${textColor};
                    --backgroundColor: ${backgroundColor};
                    --headerColor: ${headerColor};
                }
                
            `}
        </style>
    )
}