import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { signin, setСustomer } from './customerSlice';

import {ReactComponent as FlagIcon} from "../../assets/images/icons/ua_flag.svg";
import { useDispatch } from 'react-redux';

export const Signin = () => {
    const dispatch = useDispatch();
    const [warning, setWarning] = useState(null);

    const location = useLocation();

    const navigate = useNavigate();

    const redirect = location.state?.from?.pathname || '/';

    const handleSubmit = (e) => {
        e.preventDefault();
        const phone = e.target.phone.value;

        if (!/^\d*$/.test(phone)) {
            setWarning("Будь-ласка, введіть коректний номер телефону");
        } else if (phone.length !== 10) {
            setWarning("Будь-ласка, введіть 9 цифр");
        } else {
            setWarning(null);
            dispatch(signin({user: phone}))
            .then(data => {
                if (data.payload.status === "success") {
                    dispatch(setСustomer(data.payload.result));
                    navigate(redirect);
                } else {
                    setWarning(data.payload.message);
                }
            })
        }
    }

    return (
       <div className="container d-flex justify-content-center align-items-center">
            <form className="mt-5" onSubmit={handleSubmit}>
                <div className="my-3 text-center">
                    <p className="h4">Щоб отримати інформацію про замовлення, будь ласка, введіть свій номер телефону!</p>
                    <p className="h4">Якщо Bи новий клієнт зробіть своє перше замовлення, щоб пройти автоматичну реєстрацію</p>
                </div>
                <div className="input-group mb-3">
                    <span className="input-group-text " id="inputGroup-sizing-default">
                        <FlagIcon/>
                        +38
                    </span>
                    <input type="text" className="form-control "  name="phone" pattern="\d*" maxLength="10" placeholder="Введіть номер телефону"/>
                    <input type='submit' className="btn btn-dark" value={`Підтвердити`}/>
                </div>
                <div className="input-group mb-3 text-center">
                    {warning && <span className="mx-auto border border-danger p-1 rounded text-danger">{warning}</span>} 
                </div>
                
            </form>
       </div>
    )
}

