import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { user } from '../user/customerSlice';
import { createOrder } from '../cart/cartSlice';

import { TextInput } from './inputs/text-input';
import { PhoneInput } from './inputs/phone-input';
import { ShippingMethods } from './inputs/shipping-methods-input';
import { Select } from './inputs/select';
import { ShippingHandler } from '../../hoc/shipping/shipping-handler';
import { Textarea } from './inputs/textarea';
import { Checkbox } from './inputs/checkbox';
import { PayMethods } from './inputs/pay-methods';
import { PostAddress } from './inputs/post-address';

import { FixedButton } from '../template/buttons/fixed-button/fixed-button';

import './shipping.css';
import { Departments } from './inputs/departments';
import { useEffect } from 'react';
import { MINIMUM_ORDER_AMOUNT } from '../..';


export const Shipping = () => {
    const navigate = useNavigate();
    const cart = useSelector(state => state.cart.cart);
    const cartId = useSelector(state => state.cart.cartId);
    const total = useSelector(state => state.cart.cartTotal);
    const customer = useSelector(state => state.customer.customer);
    const settings = useSelector(state => state.header.settings);
    const schedule = useSelector(state => state.header.schedule);
    const dispatch = useDispatch();

    useEffect(() => {
        if (total < MINIMUM_ORDER_AMOUNT) {
            return navigate('/')
        }
    }, [total])

    return (
        <div className="container mb-5">
            <Formik
                initialValues={{
                    phone: customer?.phone ? customer?.phone : '',
                    name: customer?.name ? customer?.name : '',
                    'shipp-methods': '',
                    address: customer?.address ? customer?.address : '',
                    department: 'disabled',
                    comment: '',
                    terms: true,
                    when: 'today',
                    'shipp-day': 'today',
                    'shipp-hour': 'today',
                    'pay-methods': 'requisites',
                }}
                validationSchema={Yup.object({
                    phone: Yup.string()
                            .length(10, 'Будь-ласка, введіть 10 цифр')
                            .matches(/^[0-9]+$/, 'Некоректний номер телефону')
                            .required("Обов'язкове поле!"),
                    name: Yup.string().required("Обов'язкове поле!"),
                    'pay-methods': Yup.mixed()
                                    .required('Оберіть спосіб оплати!'),
                    'shipp-methods': Yup.mixed()
                                        .required('Оберіть тип замовлення'),
                    address: Yup.string()
                                .test('notDefault', 'Вкажіть місто або населений пунк!', function(value) {
                                    const { address } = this.parent;
                                        if (address === '') {
                                            return false;
                                        }
                                        return true;
                                })
                                .required('Вкажіть місто або населений пунк!'),
                    department: Yup.string()
                                .test('notDefault', 'Вкажіть пунк видачі посилки!', function(value) {
                                    const { department } = this.parent;
                                        if (department === 'disabled') {
                                            return false;
                                        }
                                        return true;
                                })
                                .required('Вкажіть пунк видачі посилки!'),
                    terms: Yup.boolean()
                            .required('Підтвердіть правила використання!')
                            .oneOf([true], "Підтвердіть правила використання!"),
                    when: Yup.string()
                            .required('Оберіть опцію'),
                    'shipp-day': Yup.string()
                            .test('notDefault', 'Оберіть день.', function(value) {
                                const { when } = this.parent;
                                if (when === 'other-day') {
                                    return value !== 'today';
                                }
                                return true;
                            })
                            .required('Оберіть день'),
                    'shipp-hour':  Yup.string()
                        .test('notDefault', 'Оберіть час.', function(value) {
                            const { when } = this.parent;
                            if (when === 'other-day') {
                                return value !== 'today';
                            }
                            return true;
                        })
                        .required('Оберіть час'),
                })}
                onSubmit = {(values, { setSubmitting }) => {
                    setSubmitting(true);
                    let shippingDate = null;

                    if (values['shipp-day'] !== 'today' && values['shipp-hour'] !== 'today') {
                        shippingDate = {
                            'date': values['shipp-day'],
                            'time': values['shipp-hour'],
                        }
                    }

                    let shippingMethod = null;

                    if (values['shipp-methods'] === 'pickup') {
                        shippingMethod = values['shipp-methods'];
                    }

                    localStorage.setItem('shipping', JSON.stringify({
                        'name': values.name,
                        'phone': values.phone,
                        'address': values.department + ", " + values.address,
                        'comment': values.comment,
                        shippingDate,
                        shippingMethod
                    }))

                    localStorage.setItem('payment', values['pay-methods'])

                    const payment = localStorage.getItem('payment');

                   
                        dispatch(createOrder())
                        .then(data => {
                            if (data.payload.status === 'success') {
                                if (Number(settings?.payConfirmation)) {
                                    navigate(`/success`);
                                } else {
                                    if(payment === "cash" || payment === "card" ||  payment === "requisites") {
                                        setSubmitting(false);
                                        return navigate(`/success`);
                                    } else if (payment === "liqpay") {
                                        setSubmitting(false);
                                        return navigate('/checkout', {state: {payment: payment, cartId: data.payload.result}});
                                    }
                                }
                            } else {
                                setSubmitting(false);
                                navigate(`/fail`);
                            }
                        })
                        .catch(() =>  navigate(`/fail`)) 
                }}
            >
                {({values, isSubmitting, ...props}) => {
                    
                    const title = isSubmitting ? 'Обробка...' : 'Замовити'
                    return (
                    <Form className="shipping-form pb-5">
                        <PhoneInput
                            lable="Номер телефону"
                            id="phone"
                            name="phone"
                            type="text"
                            placeholder="Номер телефону"
                            onBlur={async (e) => {
                                props.handleBlur(e);
                                const phone = e.target.value;
                              
                                dispatch(user({user: phone}))
                                .then(data => {
                                    if (data.payload && data.payload.status === 'success') {
                                        props.setFieldValue('name', data.payload.result.name);
                                        // props.setFieldValue('address', data.payload.result.address);
                                    }
                                })
                                

                            }}
                        />
                        <TextInput 
                            label='ПІБ'
                            id='name'
                            name='name'
                            type='text'
                            placeholder="Введіть Ваше ім'я"
                        />
                        {(Number(settings?.orderTypes?.shipping?.status) === 1 || Number(settings?.orderTypes?.pickup?.status) === 1) &&
                            <ShippingMethods
                                name='shipp-methods'
                                data={settings?.orderTypes}
                                isChecked={values['shipp-methods']}
                            />
                        }
                        <PostAddress
                            label='Вкажіть назву міста або населеного пункту'
                            id='post-address'
                            className={`post-address`}
                            name='address'
                            placeholder="Введіть адресу доставки"
                        />
                        {values.address && values.addressRef &&
                            <Departments
                            label='Оберіть пунк видачі'
                            id='department'
                            name='department'
                            />
                        }
                        {/* {values['shipp-methods'] === 'shipping' &&
                            <TextInput 
                                label='Адресса'
                                id='address'
                                name='address'
                                type='text'
                                placeholder="Введіть адресу доставки"
                            />
                        } */}
                        {schedule && schedule.length !== 0 &&
                            <Select
                                label='Коли?'
                                id='when'
                                name='when'
                            >
                                <option value="today">Вже</option>
                                <option value="other-day">Обрати час</option>
                            </Select>
                        }
                        {values.when === 'other-day' &&
                            <ShippingHandler schedule={schedule} date={values['shipp-day']} />
                        }
                        <Textarea
                            label="Коментар"
                            id="comment"
                            name="comment" 
                            placeholder="Додайте свої побажання"
                        />
                        <Checkbox
                            label={ <span>Я приймаю умови <Link to={`/terms-of-use`} >публічної оферти</Link></span> }
                            id="terms"
                            name="terms" 
                        />
                        {settings?.payment &&
                            <PayMethods
                                name="pay-methods"
                                data={settings?.payment}
                                isChecked={values['pay-methods']}
                            />
                        }
                        <FixedButton title={title} disabled={isSubmitting} type={'submit'}/>
                    </Form>
                )}}
            </Formik>
        </div>
    )
}