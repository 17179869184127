import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../../hook/http.hook";

const productsAdapter = createEntityAdapter();

const initialState = productsAdapter.getInitialState({
    productsLoadingStatus: 'idle',
    productImageLoadingStatus: {},
    singleproductsLoadingStatus: 'idle',
    offset: 0,
    total: 0,
    hasMore: true,
    single: false,
    productsImage: {},
});

export const fetchProducts = createAsyncThunk(
    'products/fetchProducts',
    async () => {
        const { request } = useHttp();
        const response = await request(`products`);
        return response;
    }
);

export const fetchProductImage = createAsyncThunk(
    'products/fetchProductImage',
    async ({ id }) => {
        // Проверка протокола: кэширование разрешено только при HTTPS
        if (window.location.protocol === 'https:') {
        const cache = await caches.open('images-cache');
        const cachedResponse = await cache.match(`products/image/${id}`);
        if (cachedResponse) {
            const base64Image = await cachedResponse.text();
            return { id, result: base64Image, fromCache: true };
        }
        }

        // Функция для HTTP-запросов
        const { request } = useHttp();
        const response = await request(`product/image/${id}`);

        // Кэшируем только если протокол HTTPS и ответ успешный
        if (response.status !== 'error' && window.location.protocol === 'https:') {
        const cache = await caches.open('images-cache');
        cache.put(`products/image/${id}`, new Response(response.result));
        }

        return { id, result: response.result, fromCache: false };
    }
);


export const fetchSingleProduct = createAsyncThunk(
    'products/fetchSingleProduct',
    async ({ id }) => {
        const { request } = useHttp();
        const response = await request(`product/single/${id}`);
        return response;
    }
);

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setOffset(state, action) {
            state.offset = action.payload;
        },
        setSingle(state, action) {
            state.single = action.payload;
        },
        updateLikes(state, action) {
            const {id, status} = action.payload;
            const product = state.entities[id];

            if (product) {
                const likes = parseInt(product.likes, 10);
                product.likes = status ? (likes + 1).toString() : (likes -1).toString();
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.productsLoadingStatus = "loading";
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.productsLoadingStatus = "idle";
                const validProducts = action.payload.filter(product => product !== null);
                productsAdapter.setAll(state, validProducts);
            })
            .addCase(fetchProductImage.pending, (state, action) => {
                const { id } = action.meta.arg;
                state.productImageLoadingStatus[id] = 'loading';
            })
            .addCase(fetchProductImage.fulfilled, (state, action) => {
                const { id } = action.meta.arg;
                state.productImageLoadingStatus[id] = 'idle';
                if (action.payload.status !== 'error') {
                    state.productsImage[id] = action.payload.result;
                }
            })
            .addCase(fetchProductImage.rejected, (state, action) => {
                const { id } = action.meta.arg;
                state.productImageLoadingStatus[id] = 'error';
            })
            .addCase(fetchSingleProduct.pending, (state) => {
                state.singleproductsLoadingStatus = 'loading';
            })
            .addCase(fetchSingleProduct.fulfilled, (state, action) => {
                state.singleproductsLoadingStatus = 'idle';
                state.product = action.payload.result;
            })
            .addCase(fetchSingleProduct.rejected, (state) => {
                state.singleproductsLoadingStatus = 'error';
            })
            .addDefaultCase(() => {});
    }
});

export const { selectAll } = productsAdapter.getSelectors(state => state.products);

const { reducer, actions } = productsSlice;

export default reducer;

export const { setOffset, setSingle, updateLikes } = actions;
