import { Link, useLocation, useParams } from "react-router-dom"
import { changeOrderType } from "./ordersSlice";
import { useDispatch, useSelector } from "react-redux";


import { OrdersList } from "./ordersList";

import './orders.css';
import { useEffect } from "react";

export const Orders = () => {
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const params = useParams();
    

    useEffect(() => {
        const query = params['*'] === 'completed' ? 'completed' : 'actual';
        dispatch(changeOrderType(query))
    }, [])
    
    useEffect(() => {
        return () => {
            document.body.style.overflow = 'visible';
            document.body.style.paddingRight = 0;
        };
    }, []);

    return (
        <div className="row mt-2">
                <div className="col-12 d-flex justify-content-center">
                    <Link onClick={() => dispatch(changeOrderType('actual'))} to="/orders"
                            className={pathname === '/orders' ? 'active history-nav-link' : 'history-nav-link'}>
                        Актуальні
                    </Link>
                    <Link onClick={() => dispatch(changeOrderType('completed'))} to="/orders/completed"
                            className={pathname === '/orders/completed' ? 'active history-nav-link' : 'history-nav-link'}>
                        Завершені
                    </Link>
                </div>
                <OrdersList />
            </div>
    )
}