import { useField } from "formik"


export const Textarea = ({label, ...props}) => {
    const [field, meta] = useField(props);

    return (
        <div className="mb-3">
            <label  className="form-label fs-6 fw-bold">{label}</label>
            <div className="input-group">
                <textarea className="form-control" {...field} {...props}></textarea>
            </div>
        </div>
    )
}