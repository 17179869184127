import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { useHttp } from "../../../hook/http.hook";


const categoriesAdapter = createEntityAdapter();

const initialState = categoriesAdapter.getInitialState({
    categoriesLoadingStatus: 'idle',
});

export const fetchCategories = createAsyncThunk(
    'categories/fetchCategories',
    () => {
        const { request } = useHttp();
        return request('categories');
    }
)


const categoriesSlice = createSlice({
    name: 'categories',
    initialState,
    reducers: {
        changeCategory: (state, action) => {
            state.selectedCategory = action.payload
        },
        activeCategory: (state, action) => {
            state.activeCategory = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategories.pending, state => { state.categoriesLoadingStatus = 'loading' })
            .addCase(fetchCategories.fulfilled, (state, action) => {
                state.categoriesLoadingStatus = 'idle';
                categoriesAdapter.addMany(state, action.payload)
            })
            .addCase(fetchCategories.rejected, state => { state.categoriesLoadingStatus = 'error' })
            .addDefaultCase( () => {})

    }
});

export const { selectAll } = categoriesAdapter.getSelectors(state => state.categories);


const {actions, reducer} = categoriesSlice;

export default reducer;

export const {changeCategory, activeCategory} = actions;